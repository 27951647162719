import { HeaderCheckBox, HeaderItem } from 'components/TableElements/TableElements';
import getLanguageObject from 'helpers/language';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { resultChangeStatusBulk, resultsGetResults } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import {
    NoItemsFound,
    Row,
    TableContainer,
    TableItem,
    TableItemContainer,
    TableItemIcon
} from './ResultsTable.components';
import ResultsTableProps from './ResultsTable.types';
import { updateOrganisationRequest } from 'store/user/user.actions';

export interface SelectedItemsProps {
    id: string
    status: string
}

const ResultsTable: React.FC<ResultsTableProps> = ({
    results,
    currentOrganisation,
    currentLanguage,
    permissions,
    selectedItems,
    changeSorting,
    onSelectItem,
    onSelectAll,
    updateOrganisationRequest,
    state,
}) => {
    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        updateOrganisationRequest();
    }, [])

    if (!results) {
        return (
            <TableContainer>
                <TableItemContainer canCheck={permissions.canUpdateStatusResult}>
                    <div><input type='checkbox' checked={false} onChange={onSelectAll} /></div>
                    <HeaderItem
                        onClick={() => {
                            changeSorting('status');
                        }}
                        text="Status"
                        activeItem={state.sorting === 'status'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('createdOn');
                        }}
                        text={lang.date}
                        activeItem={state.sorting === 'createdOn'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('name');
                        }}
                        text={lang.role}
                        activeItem={state.sorting === 'name'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('level');
                        }}
                        text={lang.styrLevel}
                        activeItem={state.sorting === 'level'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('organisationUnit');
                        }}
                        text={lang.organisationUnit}
                        activeItem={state.sorting === 'organisationUnit'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('unit');
                        }}
                        text={lang.unit}
                        activeItem={state.sorting === 'unit'}
                        sortingOrder={state.sortingOrder}
                    />
                    <HeaderItem
                        onClick={() => {
                            changeSorting('validatedProfile');
                        }}
                        text={lang.talentpath}
                        activeItem={state.sorting === 'validatedProfile'}
                        sortingOrder={state.sortingOrder}
                    />
                </TableItemContainer>
            </TableContainer>
        );
    }

    const tableitems = results.map(result => {
        const isSelected = selectedItems.some((x) => x.id === result.id)
        const organisationUnit = currentOrganisation?.organisationUnits.find(x => x.id === result.organisationUnitId)
        const organisationUnitName = organisationUnit?.name;
        const unit = organisationUnit?.units.find(y => y.id === result.unitId);
        const unitName = unit?.name

        return <TableItem
            key={result.id}
            id={result.id}
            status={result.status}
            date={result.createdOn}
            role={result.name}
            level={result.level}
            organisationUnit={getStringFromCurrentLanguage(organisationUnitName, currentLanguage).replace(/<[^>]*>?/gm, '').replace("&amp;", "&")}
            unit={getStringFromCurrentLanguage(unitName, currentLanguage).replace(/<[^>]*>?/gm, '').replace("&amp;", "&")}
            talentPath={getStringFromCurrentLanguage(result.validatedProfile.talentPath.names, currentLanguage) || ""}
            showCheckbox={permissions.canUpdateStatusResult}
            isSelected={isSelected}
            onSelectItem={onSelectItem}
        />
    });

    const TableItems: React.FC<{}> = () => {
        if (results.length > 0) {
            return <>{tableitems}</>;
        }
        return <NoItemsFound />;
    };

    return (
        <>
            <TableContainer>
                <TableItemContainer canCheck={permissions.canUpdateStatusResult} sticky>
                    {permissions.canUpdateStatusResult && <HeaderCheckBox activeItem={selectedItems.length !== 0} onClick={onSelectAll} />}
                    <Row>
                        <HeaderItem
                            onClick={() => {
                                changeSorting('name');
                            }}
                            text={lang.role}
                            activeItem={state.sorting === 'name'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('level');
                            }}
                            text={lang.styrLevel}
                            activeItem={state.sorting === 'level'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('organisationUnit');
                            }}
                            text={lang.organisationUnit}
                            activeItem={state.sorting === 'organisationUnit'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('unit');
                            }}
                            text={lang.unit}
                            activeItem={state.sorting === 'unit'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('validatedProfile');
                            }}
                            text={lang.talentpath}
                            activeItem={state.sorting === 'validatedProfile'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('createdOn');
                            }}
                            text={lang.date}
                            activeItem={state.sorting === 'createdOn'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            onClick={() => {
                                changeSorting('status');
                            }}
                            text="Status"
                            activeItem={state.sorting === 'status'}
                            sortingOrder={state.sortingOrder}
                        />
                        <TableItemIcon />
                    </Row>
                </TableItemContainer>
                <TableItems />
            </TableContainer>
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    permissions: state.user.permissions
});
const mapDispatchToProps = { resultsGetResults, resultChangeStatusBulk, updateOrganisationRequest };
export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);
