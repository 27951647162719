import { Language } from "types/MultiLingualString";
import { OrganisationUnit } from "types/Organisation";
import { getLevels } from "utils/levels";
import { talentPaths, transformTalentPathKey } from "utils/talentPaths";
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { Option } from 'containers/FormDropdown/FormDropdown.types';
import { FilterValues } from 'screens/Results/Results.types';

const decodeHtml = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

export const options = (lang: Language, organisationUnits: OrganisationUnit[], filterValues: FilterValues | undefined) => {
    const levels = getLevels([])

    return {
        status: [
            {
                name: 'Concept',
                value: 'concept',
            },
            {
                name: 'Active',
                value: 'active',
            },
            {
                name: 'Inactive',
                value: 'inactive',
            },
        ],
        level: levels.map((x) => ({
            name: decodeHtml(x.toUpperCase()),
            value: x
        })),
        levelOption: levels.map((x) => ({
            name: decodeHtml(x.toUpperCase()),
            uuid: x,
            shown: filterValues?.level.includes(x) || filterValues?.level.length === 0
        } as Option)),
        organisationUnit: organisationUnits.map(x => {
            return {
                name: decodeHtml(getStringFromCurrentLanguage(x.name,lang)),
                value: x.id
            }
        }),
        organisationUnitOption: organisationUnits.map(x => {
            return {
                name: decodeHtml(getStringFromCurrentLanguage(x.name,lang)),
                uuid: x.id,
                shown: filterValues?.organisationUnit.includes(x.id) || filterValues?.organisationUnit.length === 0
            } as Option
        }),
        unit: organisationUnits.flatMap(x => x.units).map(x => {
            return {
                name: decodeHtml(getStringFromCurrentLanguage(x.name,lang)),
                value: x.id
            }
        }),
        unitOption: organisationUnits.flatMap(x => x.units).map(x => {
            return {
                name: decodeHtml(getStringFromCurrentLanguage(x.name, lang)),
                uuid: x.id,
                shown: filterValues?.unit.includes(x.id) || filterValues?.unit.length === 0
            } as Option
        }),
        talentPath: talentPaths.map(x => {
            return {
                name: decodeHtml(transformTalentPathKey(x, lang) || x),
                value: x
            }
        }),
        talentPathOption: talentPaths.map(x => {
            return {
                name: decodeHtml(transformTalentPathKey(x, lang) || x),
                uuid: x,
                shown: filterValues?.talentPath.includes(x) || filterValues?.talentPath.length === 0
            } as Option
        })
    };
}
