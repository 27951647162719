import { JobFamilyMatrixStylingProps } from 'sections/JobFamilyMatrix/jobFamilyMatrix.types';
import styled from "styled-components";
import theme from "utils/theme";

export const matrixBoxStyle = `
    position: relative;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 80px;
`

export const stickyStyle = (styling?: JobFamilyMatrixStylingProps) => {
    if (!styling || (!styling.isVerticalSticky && !styling.isHorizontalSticky)) return "";

    if (styling.stickyVerticalOffset === undefined)
        styling.stickyVerticalOffset = 0;

    if (styling.stickyHorizontalOffset === undefined)
        styling.stickyHorizontalOffset = 0;

    if(styling.isVerticalSticky && styling.isHorizontalSticky) {
        return `
        position: sticky;
        box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px, rgb(242, 243, 247) 0px -10px 0px, rgb(242, 243, 247) 0px 10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) 10px -10px 0px;
        top: ${styling.stickyVerticalOffset}px;
        left: 0px;
        z-index: 8;
    `
    }

    if(styling.isVerticalSticky) {
        return `
            position: sticky;
            box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px, rgb(242, 243, 247) 0px -10px 0px, rgb(242, 243, 247) 0px 10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) 10px -10px 0px;
            top: ${styling.stickyVerticalOffset}px;
            z-index: 5;
        `
    }

    if(styling.isHorizontalSticky) {
        return `
            position: sticky;
            box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px, rgb(242, 243, 247) 0px -10px 0px, rgb(242, 243, 247) 0px 10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) 10px -10px 0px;
            left: ${styling.stickyHorizontalOffset}px;
            z-index: 6;
        `
    }

    return `
        position: sticky;
        box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px, rgb(242, 243, 247) 0px -10px 0px, rgb(242, 243, 247) 0px 10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) 10px -10px 0px;
        left: ${styling.stickyVerticalOffset}px;
        z-index: 5;
    `
}

export const MatrixBox = styled.div<{ styling?: JobFamilyMatrixStylingProps; naked?: boolean }>`
    ${matrixBoxStyle}
    background: ${props => props.naked ? "transparent" : "rgb(255, 255, 255)"};
    ${props => stickyStyle(props.styling)}
`;

export const InnerJobFamilyMatrixHeaderBox = styled.div<{ color?: string; textCenter?: boolean; bold?: boolean; styling?: JobFamilyMatrixStylingProps }>`
    padding: 12px;
    display: flex;
    justify-content:  ${props => props.textCenter ? 'center' : "flex-start"};
    align-items:  ${props => props.textCenter ? 'center' : "flex-start"};
    text-align:  ${props => props.textCenter ? 'center' : "left"};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    white-space: normal;
    background-color: ${props => props.color ? props.color : "transparent"};
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    & p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    ${props => stickyStyle(props.styling)}
`;

export const HelpContainer = styled.div`
    padding: 4px 6px;
    background-color: ${theme.colors.salmonRed};
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 5px;
    align-items: center;
    border-radius: 6px;
    color: #fff;
    box-shadow: 0 0 8px 0px rgba(0,0,0, .12);
    font-weight: bold;
`

export const InnerMatrixBox = styled(InnerJobFamilyMatrixHeaderBox)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    padding: 12px;
    box-sizing: border-box;

    .horizontalSticky {
        margin-left: 10px;
        display: none;
    }

    :hover{
        .horizontalSticky {
            display: block;
        }
    }
`

export const GroupMatrixBox = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-column: span ${props => props.columns};
    grid-gap: 10px;
    user-select: none;
`

export const GroupCompetenceMatrixBoxes = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-gap: 10px;
`

export const GroupCompetenceMatrixBox = styled.div<{ columns: number; rowCount: number }>(({ columns, rowCount }) => {
    const baseWidthOnColumnCount = () => {
        if (columns <= 1) return `repeat(${columns}, auto)`
        if (columns <= 2) return `repeat(${columns}, 660px)`
        return `repeat(${columns}, 440px)`
    }

    return `
        grid-template-columns:  265px;
        display: grid;
        grid-template-rows: repeat(${rowCount}, auto);
        grid-template-columns:  265px ${baseWidthOnColumnCount()};
        grid-auto-flow: column;
        grid-gap: 10px;
    `
});


export const CompetenciesRows = styled.div<{ rowsCount?: boolean, hasExtraCompetences?: boolean }>`
    display: grid;
    grid-template-rows: repeat(${props => props.rowsCount ? 2 : 1}, auto);
    grid-gap: ${props => props.hasExtraCompetences ? "10px" : "0"};
`

export const GroupMatrixBoxColumns = styled.div<{ columns: number }>(({ columns }) => {
    const baseWidthOnColumnCount = () => {
        if (columns <= 1) return `repeat(${columns}, auto)`
        if (columns <= 2) return `repeat(${columns}, 660px)`
        return `repeat(${columns}, 440px)`
    }

    return `
    display: grid;
    grid-template-columns:  265px ${baseWidthOnColumnCount()};
    grid-gap: 10px;
    `
})


export const GroupCompetenceMatrixBoxColumns = styled.div<{ columns: number }>(({ columns }) => {
    const baseWidthOnColumnCount = () => {
        if (columns <= 1) return `repeat(${columns}, 880px)`
        if (columns <= 2) return `repeat(${columns}, 660px)`
        return `repeat(${columns}, 440px)`
    }

    return `
    display: grid;
    grid-template-columns: ${baseWidthOnColumnCount()};
    `
})


export const GroupMatrixBoxTitle = styled.div<{ styling: JobFamilyMatrixStylingProps; }>`
    position: relative;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;

    div{
        transform: rotate(-180deg);
        writing-mode: vertical-rl;
        text-align: center;
        text-orientation: mixed;
    }
    ${props => stickyStyle(props.styling)}
`;

export const JobFamilyNotEnabledContainer = styled.div`
    height: 100%;
    display: grid;
    justify-items: center;
    grid-template-rows: 32px 1fr;
    grid-gap: 1em;
    margin-bottom: 1em;
`
type AlignmentType = "flex-start" | "center" | "flex-end"

const getAlignment = (alignment?: AlignmentType) => {
    if (alignment === "flex-start") return alignment
    if (alignment === "flex-end") return alignment

    return "center"
}

export const JobFamilyMatrixDropUp = styled.div`

    &:hover div{
        display: block;
    }
  `;

export const JobFamilyMatrixDropUpContent = styled.div`
    display: none;
    position: absolute;
    background-color: #EFEDEE;
    border-radius: 8px;
    min-width: 150px;
    bottom: 45px;
    z-index: 3;
    border: 8px solid rgb(242, 243, 247);
    &:hover{
        display: block;
    }
    & div {
        cursor: pointer;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {

            background-color: #EFEDEE;
            filter: brightness(150%);
            color: black;
        }
    }
  `;

export const JobFamilyMatrixDropUpButton = styled.div`
    &:hover {
        background-color: #f1f1f1;
    }
`;

export const JobFamilySwitchView = styled.div<{ alignment?: AlignmentType }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => getAlignment(props.alignment)};
    gap: 10px;
`;

export const JobFamilyMatrixHeader = styled.div`
    padding: 1em 1em 0;
    display: grid;
    grid-template-areas: "a b c";
    grid-template-columns: 310px 1fr auto;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    max-height: 128px;
    background-color: #fff;
    border-radius: 1em 1em 0 0;

    @media only screen and (max-width: 1200px) {
        grid-template-areas: "a c" "b b";
        padding: 1em 1em 0;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }
`;

export const JobFamilyHeaderTable = styled.div<{ columns: number }>(({ columns }) => {
    const baseWidthOnColumnCount = () => {
        if (columns <= 1) return `auto`
        if (columns <= 2) return `calc(370px + ${columns * 635}px)`
        if (columns <= 3) return `calc(370px + ${columns * 427}px)`
        if (columns <= 4) return `calc(370px + ${columns * 440}px)`
        if (columns <= 5) return `calc(400px + ${columns * 436}px)`
        return `calc(400px + ${columns * 440}px)`
    }
    const width = baseWidthOnColumnCount()

    return `
        flex: 1;
        background-color: #fff;
        width: ${width};
    `
})

export const JobFamilyDetails = styled.div`
    padding-left: 340px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
`;

export const DetailsColumns = styled.div<{ columns: number; }>(({ columns }) => {
    const baseWidthOnColumnCount = () => {
        if (columns == 1) return `435px 435px`
        if (columns == 2) return `435px 435px`
        if (columns == 3) return `650px 650px`
        return `880px 880px`
    }
    return `
    display: grid;
    grid-template-columns: ${baseWidthOnColumnCount()};
    grid-gap: 20px;
    width: 100%;

    @media only screen and (max-width: 990px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
    `
})

export const Description = styled.div`
    & p, & ul {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`
export const Table = styled.div<{ columns: number; isPrinting?: boolean; zoom?: number }>(({ columns, isPrinting, zoom }) => {
    const baseWidthOnColumnCount = () => {
        if (columns <= 1) return ``;
        if (columns <= 2) return `repeat(${columns}, 660px)`
        return `repeat(${columns}, 440px)`
    }

    const getZoom = () => {
        if (isPrinting) {
            return 100;
        }
        if (zoom) {
            return zoom;
        }
        return 100;
    }
    return `
        zoom: ${getZoom()}%;
        padding-bottom: 10px;
        padding-top: 10px;
        display: inline-grid;
        grid-template-columns: 330px ${baseWidthOnColumnCount()};
        grid-gap: 10px;
        position: relative;
        background-color: #eff4f9;
    `
})

export const ToggleList = styled.div`
    width: 300px;
`

export const TextArea = styled.textarea`
    padding: 8px;
    height: fit-content;
    min-height: 240px;
    width: 100%;
    display: block;
    line-height: 30px;
    border: 2px solid #eee;
    border-radius: 5px;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    resize:none;
    cursor: text;
    box-sizing: border-box;
    white-space: pre-wrap;
    &:focus{
            border: 2px solid #333;
    }

    font-family: Arial;
`;


export const MatrixBoxControllers = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const DeleteButton = styled.div`
    position: absolute;
    top: 12px;
    right: 4px;
`
export const MatrixLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    grid-area: a;
`

export const MatrixDetails = styled.div`
    grid-area: b;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    @media only screen and (max-width: 1200px) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr auto;
    }
`

export const StyrLogo = styled.img`
    height: auto;
    width: 150px;
`
export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`

export const Label = styled.label<{ lastOfType?: boolean }>`
    margin-bottom: 5px;
    padding: 5px 0;
    display: grid;
    grid-template-columns: auto 1fr 20px;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &:hover{
        background-color: rgba(0,0,0, .02);
    }
`

export const Topbar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const Footer = styled.div`
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 0 0 1em 1em;
    font-weight: bold;
`

export const JobFamilyMatrixContainer = styled.div<{ printing?: boolean }>`
    display: grid;
    grid-template-rows: 1fr 70px;
    height: 100%;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const StyrGroupMatrixBox = styled.div<{ rowSpan: number; sticky?: string; }>`
    display: grid;
    grid-gap: 1em;
    grid-column: span ${props => props.rowSpan};
`

export const StyrGroupInnerMatrixBox = styled.div<{ rowSpan?: number; color: string; }>`
    text-align: center;
    text-orientation: mixed;
    background: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 10px;
`;

export const DateHeaderContainer = styled.div`
    grid-area: c;
    font-weight: bold;
    font-size: 14px;
`

export const DateNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

`

export const LastEditedByContainer = styled.div`
    font-size: 12px;
    font-weight: bold;
`

export const ErrorTitle = styled.h5`
    margin: 5px 0;
    font-weight: bold;
`

export const ErrorContentContainer = styled.div`
max-width: 350px;
`

export const ErrorContentElement = styled.p`
    font-size: 12px;
    font-weight: bold;
    display: inline;
`

export const DownloadListContainer = styled.div`
    margin: 0 2px 1rem;
    display: flex;
    flex-direction: column;
    height: 280px;
    overflow-y: auto;

    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.05) inset;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const DownloadListItem = styled.div`
    color: black;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    border-bottom: 1px solid rgba(0,0,0,0.05);

    button {
        cursor: pointer;
        background: none;
        border: none;

        &:hover {
            background: #f1f1f1;
            border-radius: 4px;
        }
    }
`;

export const DownloadListNoItems = styled.div`
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;
