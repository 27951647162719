import Button from 'components/Button/Button';
import {
    H2,
    TextSmall
} from 'components/Typography/Typography';
import { Container } from 'containers/ContentBox/ContentBox';
import InputField from 'containers/InputField/InputField';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { onKeyDown } from 'helpers/form';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConnectedManageOrganisationImage from '../ManageOrganisationImage/ManageOrganisationImage.connector';
import ManageOrganisationNameProps from './ManageOrganisation.types';
import { Content } from './PartnerSection.components';
import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import Toggle from 'components/Toggle/Toggle';
import ToggleFactors from 'sections/ToggleFactors/ToggleFactors';
import ListItemWithToggle from 'containers/ListItemWithToggle/ListItemWithToggle';
import { SsoConfiguration } from 'types/Organisation';

const ManageOrganisation: React.FC<ManageOrganisationNameProps> = ({
    createNewOrganisation,
    duplicateOrganisation,
    deleteOrganisation,
    updateOrganisationName,
    updateOrganisationTMALink,
    updateOrganisationJobFamilies,
    updateOrganisationMatrixes,
    getCurrentSsoConfigurations,
    updateCurrentSsoConfiguration,
    canDuplicateOrganisation,
    currentLanguage,
    currentOrganisation,
    currentSsoConfigurations,
    existing,
    organisations,
    permissions,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();

    const [name, setName] = useState(currentOrganisation?.name || "");
    const [createName, setCreateName] = useState("");
    const [copyName, setCopyName] = useState("");
    const [tmaLink, setTmaLink] = useState(currentOrganisation?.options?.tmaLink || "");

    const [currentSsoConfiguration, setCurrentSsoConfiguration] = useState(currentSsoConfigurations && currentSsoConfigurations.length > 0 ? currentSsoConfigurations[0] : {} as SsoConfiguration);

    const [error, setError] = useState({
        exist: false,
        value: false,
    });

    if (existing && !currentOrganisation) {
        return null;
    }

    if (permissions.canConfigureSso) {
        useEffect(() => {
            getCurrentSsoConfigurations();
        }, [currentSsoConfiguration]);
    }

    const submitCreate = () => {
        const organisationExists = organisations?.find(x => x.name === createName);
        if (!createName) return setError({ exist: false, value: true })
        if (organisationExists) return setError({ exist: true, value: false })

        setError({ exist: false, value: false })
        createNewOrganisation(createName, history);
        setCreateName('');
    };


    const submitChange = () => {
        const organisationExists = organisations?.find(x => x.name === name);
        if (!name) return setError({ exist: false, value: true })
        if (organisationExists) return setError({ exist: true, value: false })

        setError({ exist: false, value: false })
        updateOrganisationName(currentOrganisation?.id || "", name);
    };

    const submitChangeTma = () => {
        if (!currentOrganisation) return;

        updateOrganisationTMALink(currentOrganisation?.id || "", tmaLink, currentOrganisation?.options?.jobFamilies);
    }

    const MaybeRenderError = () => {
        if (error.exist) return <InputFieldErrorMessages text="Organisation already exists" />
        if (error.value) return <InputFieldErrorMessages text="Please enter organisation name" />

        return null;
    }

    const submitDuplicateOrganisation = () => {
        if (!currentOrganisation) return;
        duplicateOrganisation(copyName, currentOrganisation.id, history);
    }

    const submitSsoConfiguration = () => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        if (!currentOrganisation ||
            !currentSsoConfiguration ||
            !currentSsoConfiguration.domain ||
            !currentSsoConfiguration.tenantId ||
            !uuidRegex.test(currentSsoConfiguration.tenantId)) {
            setError({ exist: false, value: true });
            return;
        }

        updateCurrentSsoConfiguration(currentSsoConfiguration);
    }

    const setSsoDomain = (value: string) => {
        setCurrentSsoConfiguration({ ...currentSsoConfiguration, domain: value });
    }

    const setSsoTenantId = (value: string) => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        if (!currentOrganisation || !uuidRegex.test(value)) {
            setError({ exist: false, value: true });
            return;
        }
        setCurrentSsoConfiguration({ ...currentSsoConfiguration, tenantId: value });
    }

    const onDelete = () => {
        if (!currentOrganisation) return;
        deleteOrganisation(currentOrganisation.id, history);
    }

    useEffect(() => {
        setName(currentOrganisation?.name || "")
        setTmaLink(currentOrganisation?.options?.tmaLink || "")
    }, [currentOrganisation?.name, currentOrganisation?.options?.tmaLink])

    return (
        <>
            <div>
                <Container>
                    <Content>
                        <H2>{lang.organisationName}</H2>
                        <InputField
                            placeholder={lang.organisationName}
                            type="text"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            onKeyDown={event => onKeyDown(event, submitChange)}
                            errorMessage={
                                <MaybeRenderError />
                            }
                            fullWidth />
                        <Button text={lang.editPlural} onClick={submitChange} priority="primary" />
                    </Content>
                </Container>
                <Container>
                    <ConnectedManageOrganisationImage />
                </Container>
                {permissions.canDeleteOrganisation && (
                    <Container>
                        <Content>
                            <H2>{lang.deleteOrganisation}</H2>
                            <AreYouSurePopup
                                onClick={onDelete}
                                action={lang.delete}
                                item={currentOrganisation?.name || ""}
                                InnerContent={() => <Button text={lang.delete} priority="primary" />} />
                        </Content>
                    </Container>
                )}
                {
                    permissions.canDuplicateOrganisation && (
                        <Container>
                            <Content>
                                <H2>TMS (TMA) Link</H2>
                                <InputField
                                    placeholder={"link"}
                                    type="text"
                                    errorMessage={
                                        <MaybeRenderError />
                                    }
                                    onChange={event => setTmaLink(event.target.value)}
                                    value={tmaLink}
                                    fullWidth />
                                <Button text={lang.save} onClick={submitChangeTma} priority="primary" />
                            </Content>
                        </Container>
                    )
                }
                {
                    permissions.canCreateOrganisation && (
                        <Container>
                            <Content>
                                <H2>Job Profiler</H2>
                                <div onClick={() => {
                                    if (!currentOrganisation) return;
                                    updateOrganisationJobFamilies(currentOrganisation?.id || "", !currentOrganisation?.options?.jobFamilies);
                                }}>
                                    <Toggle state={Boolean(currentOrganisation?.options?.jobFamilies)} />
                                </div>
                            </Content>
                        </Container>
                    )
                }

                {permissions.canManageOrganisation && (
                    <Container>
                        <Content>
                            <h2>{lang.manageMatrixes}</h2>
                            <TextSmall>{lang.manageMatrixesDescription}</TextSmall>
                            {currentOrganisation &&
                                <>
                                    <ListItemWithToggle label={lang.jobMatrix} state={currentOrganisation.jobMatrixEnabled ?? false}
                                        onChange={(state) => {
                                            if (currentOrganisation) {
                                                currentOrganisation.jobMatrixEnabled = state;
                                                const talentMatrixEnabled = (!currentOrganisation.jobMatrixEnabled && !currentOrganisation.talentMatrixEnabled) ? true : currentOrganisation.talentMatrixEnabled ?? false;
                                                updateOrganisationMatrixes(currentOrganisation.id, currentOrganisation.jobMatrixEnabled ?? false, talentMatrixEnabled);
                                            }
                                        }}
                                    />
                                    <ListItemWithToggle label={lang.talentMatrix} state={currentOrganisation.talentMatrixEnabled ?? false}
                                        onChange={(state) => {
                                            if (currentOrganisation) {
                                                currentOrganisation.talentMatrixEnabled = state;
                                                const jobMatrixEnabled = (!currentOrganisation.jobMatrixEnabled && !currentOrganisation.talentMatrixEnabled) ? true : currentOrganisation.jobMatrixEnabled ?? false;
                                                updateOrganisationMatrixes(currentOrganisation.id, jobMatrixEnabled, currentOrganisation.talentMatrixEnabled ?? false);
                                            }
                                        }}
                                    />
                                </>}
                        </Content>
                    </Container>
                )}
            </div>
            <div>
                {permissions.canCreateOrganisation && (
                    <Container>
                        <Content>
                            <H2>{lang.createOrganisation}</H2>
                            <InputField
                                placeholder={lang.organisationName}
                                type="text"
                                value={createName}
                                onChange={event => setCreateName(event.target.value)}
                                onKeyDown={event => onKeyDown(event, submitCreate)}
                                errorMessage={
                                    <MaybeRenderError />
                                }
                                fullWidth />
                            <Button text={lang.createNewOrganisation} onClick={submitCreate} priority="primary" />
                        </Content>
                    </Container>
                )}
                {canDuplicateOrganisation && (
                    <Container>
                        <Content>
                            <H2>{lang.duplicateOrganisation}</H2>
                            <InputField
                                placeholder={lang.organisationName}
                                type="text"
                                value={copyName}
                                onChange={event => setCopyName(event.target.value)}
                                onKeyDown={event => onKeyDown(event, submitDuplicateOrganisation)}
                                errorMessage={
                                    <MaybeRenderError />
                                }
                                fullWidth />
                            <Button text={lang.duplicateOrganisation} onClick={submitDuplicateOrganisation} priority="primary" />
                        </Content>
                    </Container>
                )}
                {permissions.canConfigureSso && (
                    <Container>
                        <Content>
                            <h2>{lang.ssoEditHeader}</h2>
                            <InputField
                                placeholder={lang.ssoDomain}
                                type="text"
                                value={currentSsoConfiguration.domain}
                                onChange={event => setSsoDomain(event.target.value)}
                                errorMessage={
                                    <MaybeRenderError />
                                }
                                fullWidth />
                            <InputField
                                placeholder={lang.ssoTenantId}
                                type="text"
                                value={currentSsoConfiguration.tenantId}
                                onChange={event => setSsoTenantId(event.target.value)}
                                errorMessage={
                                    <MaybeRenderError />
                                }
                                fullWidth />
                            <Button text={lang.saveSSOConfiguration} onClick={submitSsoConfiguration} priority="primary" />
                        </Content>
                    </Container>
                )}

                {permissions.canEditDifferentiatingFactors && (
                    <Container>
                        <Content>
                            <h2>{lang.manageJobFamily}</h2>
                            <H2>{lang.manage + " " + lang.differentiatingFactorsTitle.toLowerCase()}</H2>
                            {currentOrganisation &&
                                <ToggleFactors factors={currentOrganisation.differentiatingFactors} />}
                        </Content>
                    </Container>
                )}
            </div>
        </>
    );
};

export default ManageOrganisation;
