import { connect } from 'react-redux';
import DocumentsBlocksSection from 'sections/DocumentsBlocksSection/DocumentsBlocksSection';
import ReduxStore from 'store/store.type';
import { invitePartner } from 'store/user/user.actions';

const mapStateToProps = (state: ReduxStore) => ({
    currentOrganisation: state.user.currentOrganisation,
    currentLanguage: state.language.currentLanguage,
    isSharedLink: state.user.isShareLink
});

const mapDispatchToProps = { invitePartner };
const ConnectedDocumentsBlocksSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsBlocksSection);

export default ConnectedDocumentsBlocksSection;
