import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import getLanguageObject from 'helpers/language';
import { useNavigate } from 'helpers/useNavigation';
import { Header } from 'layouts/MainLayout/MainLayout.components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import ConnectedResultValidatedProfile from 'sections/ValidatedProfiles/ResultValidatedProfile/ResultValidatedProfile.connector';
import ConnectedResultSummary from 'sections/ValidatedProfiles/ResultSummary/ResultSummary.connector';
import FilterInputDropdown from 'sections/ResultsFilters/ResultsFilters.components';
import { options } from 'sections/ResultsFilters/ResultsFilters.data';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import styled from 'styled-components';
import theme from 'utils/theme';
import ConnectedComparingLevels from '../ComparingLevels/ComparingLevels.connector';
import { PageDetails, ResultPageContainer } from './ResultSection.components';
import ResultSectionProps from './ResultSection.types';
import TrashIcon from 'components/Icons/trash';
import PrintIcon from 'components/Icons/print';
import RedoIcon from 'components/Icons/redo';
import EditIcon from 'components/Icons/edit';

const ResultSection: React.FC<ResultSectionProps> = ({
    id, resultsGetResult, resultsChangeResultValidatedProfile, results, permissions,
    currentLanguage, currentOrganisation, resultsDeleteResult, canDeleteResult, resultsChangeResult,
    isShareLink, users, updateOrganisationRequest
}) => {
    const result = results.find(r => r.id.toString() === id.toString());
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();
    const { navigate } = useNavigate();

    const [showTalentpath, setShowTalentPath] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        updateOrganisationRequest();
    }, [updateOrganisationRequest])

    useEffect(
        () => {
            resultsGetResult(id);
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    const goBack = () => setShowTalentPath(!showTalentpath);

    const handleChangeResultValidatedProfile = (currentId: string, validatedProfileId: string) => {
        if (!currentId) return;

        resultsChangeResultValidatedProfile(currentId, validatedProfileId);
        goBack();
    }

    if (!result || !result.validatedProfile) {
        return null;
    }

    const MaybeShowDeleteButton = () => {
        if (canDeleteResult) {
            return (
                <AreYouSurePopup
                    onClick={() => resultsDeleteResult(id, history)}
                    action={lang.deletePlural}
                    item={lang.result}
                    InnerContent={() => (
                        <IconButton IconSVG={TrashIcon} color="danger" onClick={() => { }} />
                    )}
                />
            );
        }
        return null;
    };
    const onChangeValidatedProfileStatus = (status: string) => {
        resultsChangeResult(status, getStringFromCurrentLanguage(result.jobInformations?.extraField, currentLanguage), id, history);
    }

    const onClickPrintResult = () => navigate(`${Routes.PdfRoute + id}/${currentLanguage}`)
    const onClickCanEdit = () => setCanEdit((canEdit: boolean) => !canEdit)
    const onClickRetry = () => history.push(Routes.AllocatorRoute, {
        profile: {
            name: result.name,
            organisationUnit: result.organisationUnitId,
            unit: result.unitId,
        },
    });

    const isCreatorUsingUsername = () => {
        if (!result.updater || result.updater.includes('@') || result.updater === "OrganisationImporter") {
            return null;
        }

        const user = users?.find(u => u.objectId === result.updater || u.id === result.updater);

        if (!user) {
            return null
        }

        const getName = () => {
            if (user.firstName || user.lastName) {
                return user.firstName + " " + user.lastName
            }
            return user.emailAdress.split("@")[0];
        }

        return <PageDetails>
            {!getName().toLowerCase().includes("unknown") && <small>
                <strong>{lang.updatedBy}: </strong><span>{getName()}</span>
            </small>}
        </PageDetails>
    };

    if (!currentOrganisation) return null;

    return showTalentpath ? (
        <ConnectedComparingLevels id={id} result={result} goBack={goBack} changeResultValidatedProfile={handleChangeResultValidatedProfile} currentOrganisation={currentOrganisation} />
    ) : (
        <Container>
            <ResultContainer>
                <Header>
                    <BreadCrumbs title={lang.styrProfile} paths={
                        [
                            { title: lang.searchJobsnRoles, path: Routes.ResultsRoute },
                            { title: `${lang.result}: ${result.name}`, path: Routes.ResultRoute, id: id.toString() }
                        ]
                    } />
                </Header>
                {isCreatorUsingUsername()}
                <ResultPageContainer>
                    <ValidatedProfilePage>
                        <ConnectedResultSummary id={id} result={result} canEdit={canEdit} />
                        <ConnectedResultValidatedProfile id={id} result={result} canEdit={canEdit} setCanEdit={setCanEdit} note={getStringFromCurrentLanguage(result.jobInformations?.extraField, currentLanguage)} />
                    </ValidatedProfilePage>
                </ResultPageContainer>
            </ResultContainer>
            {
                !isShareLink && (
                    <ConnectedBottomNavigation>
                        <ControllerButtons>
                            {permissions.canUpdateStatusResult &&
                                <FilterInputDropdown
                                    label="Status"
                                    onChange={(e) => onChangeValidatedProfileStatus(e.target.value)}
                                    options={options(currentLanguage, [], undefined).status}
                                    selected={result.status.toLowerCase()}
                                    showAll={false}
                                />}
                            {permissions.canUpdateStatusResult && <IconButton IconSVG={PrintIcon} color="warning" onClick={onClickPrintResult} />}
                            {(result.status.toLowerCase() === "concept" || permissions.canUpdateStatusResult) && <IconButton IconSVG={RedoIcon} color="warning" onClick={onClickRetry} />}
                            {permissions.canDeleteResult && <MaybeShowDeleteButton />}
                            {permissions.canUpdateStatusResult && <IconButton IconSVG={EditIcon} color={!canEdit ? "warning" : "warningSticky"} onClick={onClickCanEdit} />}
                        </ControllerButtons>
                        <ControllerButtons style={{
                            width: (result.status.toLowerCase() === "concept" || permissions.canUpdateStatusResult) ? "400px" : "260px"
                        }}>
                            {result.status === 'active' && <Button text={lang.viewJobMatrix} priority="secondary" onClick={() => navigate(`${Routes.JobMatrixRoute}?id=${result!.id}`)} />}
                            {(result.status.toLowerCase() === "concept" || permissions.canUpdateStatusResult) && <Button text={lang.changeStyrLevel}
                                priority="primary"
                                onClick={goBack}
                            />}
                        </ControllerButtons>
                    </ConnectedBottomNavigation>
                )
            }
        </Container>
    )
};

export default ResultSection;


export const Container = styled.div<{ sidebarOpen?: boolean }>`
    display: grid;
    grid-template-rows: 1fr 70px;
    height: 100%;
    max-height: calc(100vh - 70px);
    overflow: auto;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const ResultContainer = styled.div`
    padding: 50px ;
    overflow-y: auto;
`

const ValidatedProfilePage = styled.div`
    margin: 0 50px;
    margin-bottom: 50px;
    background-color: #eff4f9;
    box-shadow: ${theme.boxShadow};
`;

export const ControllerButtons = styled.div`
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
`

export const Controller = styled.div<{ sidebarOpen?: boolean }>`
    padding: 1em;
    width: ${props => props.sidebarOpen ? "calc(100vw - 260px)" : "calc(100vw - 50px)"};
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1em;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    box-sizing: border-box;
`
