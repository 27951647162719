import ReduxStore from './store.type';

type LoadState = () => undefined | ReduxStore['user'];

export const loadState: LoadState = () => {
    const serializedState = localStorage.getItem('styr-state-8');
    if (!serializedState) {
        return undefined;
    }
    return {
        ...JSON.parse(serializedState),
        errors: [],
    };
};

export const saveState = (state: { user: ReduxStore['user'] }) => {
    const serializedState = JSON.stringify({
        ...state.user,
        organisations: state.user.organisations.map(org => ({
            id: org.id,
            name: org.name,
            options: org.options
        })),
    });

    try {
        localStorage.setItem('styr-state-8', serializedState);
        const date = new Date();
        date.setHours(date.getHours() + 24);
        localStorage.setItem('styr-expire', date.toString());
    } catch {
        console.log("serializedState", serializedState);
    }
};
