import styled from 'styled-components';

export const Container = styled.div`
    height: calc(100vh - 119px);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        flex-direction: column;
    }
`;
