import Button from 'components/Button/Button';
import NumberTag from 'components/NumberTag/NumberTag';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import React, { useRef, useState } from 'react';
import { Container, DropdownSelect, FormDropdown, Icon, Label, Option } from './FormDropdown.components';
import FormDropdownCheckListProps, { FormDropdownCheckListEmptyProps } from './FormDropdown.types';
import ArrowDownIcon from 'components/Icons/arrowDown';

const FormDropdownCheckList: React.FC<FormDropdownCheckListProps> = ({ options, onCheckBox, label, levels, customWidth }) => {

    const [isVisible, setIsVisible] = useState(false);

    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = () => {
        setIsVisible(!isVisible);
    }

    useOnClickOutside(ref, handleClickOutside)

    const renderValue = (value: string) => {
        if (levels) return (
            <NumberTag level={value} size={32} />
        )

        return <span dangerouslySetInnerHTML={{
            __html: value
        }}/>
    }

    return (
        <Container >
            <FormDropdown onClick={handleClickOutside}>
                <Label>{label}</Label>
                <Icon>
                    <ArrowDownIcon color="#000" />
                </Icon>
            </FormDropdown>
            {isVisible &&
                <DropdownSelect ref={ref} customWidth={customWidth} openInverted>
                    {options.map(option => {
                        const name = option.name;
                        return (
                            <Option key={option.uuid}>
                                {renderValue(name)}
                                <input type="checkbox" value={option.uuid} onChange={() => onCheckBox(option.uuid)} checked={option.shown} />
                            </Option>
                        );
                    })}
                </DropdownSelect>
            }
        </Container>
    );
};

export default FormDropdownCheckList;

export const FormDropdownCheckListEmpty: React.FC<FormDropdownCheckListEmptyProps> = ({ button, children, label, openInverted }) => {

    const [isVisible, setIsVisible] = useState(false);

    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = () => {
        setIsVisible(!isVisible);
    }

    useOnClickOutside(ref, handleClickOutside)

    const renderLabelOrButton = () => {
        if (button) {
            return <Button text={label} onClick={handleClickOutside} />
        } else {
            return <span>{label}</span>
        }
    }

    return (
        <Container >
            {renderLabelOrButton()}
            {isVisible &&
                <DropdownSelect customWidth={270} ref={ref} openInverted={openInverted}>
                    {children}
                </DropdownSelect>
            }
        </Container>
    );
};
