import getLanguageObject from 'helpers/language';
import React from 'react';
import { BlocksGrid, DocumentsBlocks, Header } from './DocumentsBlocksSection.components';
import DocumentsBlockSectionProps, { DocumentsBlockProps } from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.types';
import manual from '../../assets/pdfs/Handleiding Styr Dock.pdf';
import faq from '../../assets/pdfs/FAQ t.b.v. Styr implementaties.pdf';
import flyerCompensation from '../../assets/pdfs/Flyer Styr Een nieuw beloningsbeleid.pdf';
import flyerJobMatrix from '../../assets/pdfs/Flyer Styr Een nieuw functiehuis.pdf';
import implementation from '../../assets/pdfs/Implementatie informatie.pptx';
import compensationPolicy from '../../assets/pdfs/STYR Beloningsbeleid data NL 2025.xlsx';
import benchmark from '../../assets/pdfs/Styr Benchmark NL 2025.pdf';
import objectionProcedure from '../../assets/pdfs/Template Bezwaarprocedure.docx';
import templateAgreementCompensation from '../../assets/pdfs/Template Instemmingsaanvraag Beloningsbeleid.docx';
import templateAgreementJobGrading from '../../assets/pdfs/Template instemmingsaanvraag functiewaardering.docx';
import templateMaintenanceMatrix from '../../assets/pdfs/Template Onderhoudsprocedure Job Matrix voor rollen.docx';
import templateMaintenanceGeneral from '../../assets/pdfs/Template onderhoudsprocedure.docx';
import templatePlanningImplementation from '../../assets/pdfs/Template Planning implementatie.xlsx';
// Template Bezwaarprocedure.docx

import { Container } from 'layouts/MainLayout/MainLayout.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Language } from 'types/MultiLingualString';

interface Block extends DocumentsBlockProps {
    show: boolean;
    languages: Language[];
}

const DocumentsBlocksSection: React.FC<DocumentsBlockSectionProps> = ({ isSharedLink, currentLanguage, currentOrganisation }) => {
    let lang = getLanguageObject(currentLanguage);
    const documentHeaderText = lang.documentOverview;

    let filterLanguage = currentLanguage;
    if(currentLanguage !== 'nl-NL' && currentLanguage !== 'nl-BE'){
        lang = getLanguageObject('en-EN');
        filterLanguage = 'en-EN';
    }

    const blocks: Block[] = [{
        title: lang.manual,
        image: require('../../assets/images/Styr_kennisbank v2.png'),
        navigateToSite: manual,
        show: true,
        languages: ['nl-NL', 'nl-BE', 'en-EN']
    },

    // FAQ
    // {
    //     title: lang.faq,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: faq,
    //     show: true
    // },
    // Flyer Compensation
    // {
    //     title: lang.flyerCompensation,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: flyerCompensation,
    //     show: true,
    //     languages: ['nl-NL', 'nl-BE', 'en-EN']
    // },
    // Flyer Job Matrix
    // {
    //     title: lang.flyerJobMatrix,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: flyerJobMatrix,
    //     show: true
    // },
    // Implementation
    // {
    //     title: lang.implementation,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: implementation,
    //     show: true
    // },
    // Reward Policy
    {
        title: lang.compensationPolicy,
        image: require('../../assets/images/Styr_kennisbank v2.png'),
        navigateToSite: compensationPolicy,
        show: true,
        languages: ['nl-NL', 'nl-BE', 'en-EN']
    },
    // Benchmark
    {
        title: lang.benchmark,
        image: require('../../assets/images/Styr_kennisbank v2.png'),
        navigateToSite: benchmark,
        show: true,
        languages: ['nl-NL', 'nl-BE', 'en-EN']
    },
    // Objection Procedure
    // {
    //     title: lang.objectionProcedure,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: objectionProcedure,
    //     show: true
    // },
    // Template Agreement Compensation
    // {
    //     title: lang.templateAgreementCompensation,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: templateAgreementCompensation,
    //     show: true,
    //     languages: ['nl-NL', 'nl-BE', 'en-EN']
    // },
    // Template Agreement Job Grading
    // {
    //     title: lang.templateAgreementJobGrading,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: templateAgreementJobGrading,
    //     show: true
    // },
    // Template Maintenance Matrix
    // {
    //     title: lang.templateMaintenanceMatrix,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: templateMaintenanceMatrix,
    //     show: true
    // },
    // Template Maintenance General
    // {
    //     title: lang.templateMaintenanceGeneral,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: templateMaintenanceGeneral,
    //     show: true
    // },
    // Template Planning Implementation
    // {
    //     title: lang.templatePlanningImplementation,
    //     image: require('../../assets/images/Styr_kennisbank v2.png'),
    //     navigateToSite: templatePlanningImplementation,
    //     show: true
    // },
    ]

    return (

        <Container>
            <Header>
                <BreadCrumbs
                    title={documentHeaderText}
                    paths={[
                    ]}
                />
            </Header>
                <BlocksGrid>
                    {blocks.filter(x => x.languages.includes(filterLanguage)).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
        </Container>
    );
};

export default DocumentsBlocksSection;
