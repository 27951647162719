import { useEffect, useReducer } from "react";
import { localDataReducer } from "./JobMatrixResults.reducer";
import { initialLocalJobMatrixData } from "./JobMatrixResults.types";
import initialJobMatrixData, { LOCAL_STORAGE_KEY } from "./initialJobMatrixData";

export const useJobMatrixHelper = (organisationId: string | undefined) => {
    const [localData, localDataDispatch] = useReducer(localDataReducer, initialLocalJobMatrixData);
    // Load the last local data from the local storage.
    useEffect(() => {
        let key = LOCAL_STORAGE_KEY;
        if (organisationId) {
            key = `${organisationId}-${key}`;
        }

        const localString = localStorage.getItem(key);
        if (localString) {
            const locallyStoredData = JSON.parse(localString);
            localDataDispatch({
                type: "init",
                payload: locallyStoredData,
                organisationId
            })
        } else {
            localDataDispatch({
                type: "init",
                payload: initialJobMatrixData,
                organisationId
            })
        }
    }, [])

    const enlargeWidthColumn = (uuid: string) => {
        localDataDispatch({
            type: "enlargeWidthColumn",
            payload: {
                uuid,
                organisationId
            }
        })
    }

    const shrinkWidthColumn = (uuid: string) => {
        localDataDispatch({
            type: "shrinkWidthColumn",
            payload: {
                uuid,
                organisationId
            }
        })
    }


    const changeStickyColumn = (uuid: string) => {
        localDataDispatch({
            type: "toggleStickyColumn",
            payload: {
                uuid,
                organisationId
            }
        })
    }

    const changeVisibilityRow = (level: string) => {
        localDataDispatch({
            type: "toggleVisibilityRow",
            payload: {
                uuid: level,
                organisationId
            }
        })
    }

    const changeVisibilityColumn = (uuid: string, newValue?: boolean) => {
        localDataDispatch({
            type: "toggleVisibilityColumn",
            payload: {
                uuid,
                newValue,
                organisationId
            }
        })
    }

    const changeVisibilityTalentpath = (uuid: string, newValue?: boolean) => {
        localDataDispatch({
            type: "toggleVisibilityTalentpath",
            payload: {
                uuid,
                newValue,
                organisationId
            }
        })
    }

    const changeFilterTag = (tag: string) => {
        localDataDispatch({
            type: "toggleTags",
            payload: {
                tag,
                organisationId
            }
        })
    }

    const controllerHandlers = {
        changeStickyColumn,
        changeVisibilityColumn,
        changeVisibilityTalentpath,
        changeVisibilityRow,
        enlargeWidthColumn,
        shrinkWidthColumn,
        changeFilterTag
    }

    return {
        controllerHandlers,
        localData
    }
}
