import Button, { BackButton } from 'components/Button/Button';
import { H2 } from 'components/Typography/Typography';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { CompareWithLevelPopUpContainer, Container, ContainerHeader, DropdownSelect, ErrorText, Icon, InnerPopup, LevelOption, ListWithLevels, Navigation } from './CompareWithLevelPopUp.components';
import CompareWithLevelPopUpProps from './CompareWithLevelPopUp.types';
import { getLevelIndex } from 'utils/levels';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import ArrowDownIcon from 'components/Icons/arrowDown';

const CompareWithLevelPopUp: React.FC<CompareWithLevelPopUpProps> = ({
    results,
    selectedLevels,
    handleSelectedLevel,
    currentLanguage,
    InnerContent,
    goBack,
    currentLevel,
    currentTalentpath
}) => {
    const [visible, setVisible] = useState(true);
    const [error, setError] = useState(false);
    const [selectedTalentPathKey, setSelectedTalentPathKey] = useState('');
    const lang = getLanguageObject(currentLanguage);
    const ref = useRef(null);
    useOnClickOutside(ref, () => close());

    useEffect(() => {
        setSelectedTalentPathKey(currentTalentpath ?? '');
    }, [currentTalentpath]);

    const sortedResults = results?.sort((a, b) => {
        const levelA = a.styrLevel.level;
        const levelB = b.styrLevel.level;
        const levelIndexA = getLevelIndex(levelA)
        const levelIndexB = getLevelIndex(levelB)
        return levelIndexB - levelIndexA;
    })

    const filteredResults = sortedResults?.filter((item) => item.talentPath.key === selectedTalentPathKey);

    const talentPaths = Array.from(new Set(results?.map((item) => item.talentPath.key))).map(key => {
        const item = results?.find(result => result.talentPath.key === key);

        const selectedItemsInTalentPath = selectedLevels.filter(level => level.split('-')[0] === key).length;
        return {
            id: item?.talentPath.id,
            key: item?.talentPath.key,
            name: item?.talentPath.names[currentLanguage] + (selectedItemsInTalentPath > 0 ? ` (${selectedItemsInTalentPath})`: ''),
        };
    });

    if (talentPaths?.length > 0 && !selectedTalentPathKey) {
        setSelectedTalentPathKey(talentPaths[0].key ?? '');
    }

    const changeTalentPath = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const talentPath = e.target.value;
        if (talentPath)
            setSelectedTalentPathKey(talentPath);
    }

    const close = () => {
        if (selectedLevels.length < 2) return setError(true);
        setVisible(false);
        setError(false);
    };

    const open = () => {
        setVisible(true);
    };

    return (
        <>
            {visible ? (
                <>
                    <div onClick={open}>
                        {InnerContent && <InnerContent />}
                    </div>
                    <CompareWithLevelPopUpContainer>
                        <InnerPopup ref={ref}>
                            <ContainerHeader>
                                <H2>{lang.chooseStyrLevels(selectedLevels.length)}</H2>
                                <Container>
                                    <DropdownSelect value={selectedTalentPathKey} onChange={changeTalentPath}>
                                        {talentPaths.map((option) => {
                                            (option.key, selectedTalentPathKey);
                                            return <option selected={option.key === selectedTalentPathKey} key={option.id} value={option.key}>{option.name}</option>})}
                                    </DropdownSelect>
                                    <Icon>
                                        <ArrowDownIcon color="#000" />
                                    </Icon>
                                </Container>
                            </ContainerHeader>
                            <p>{lang.selectStyrLevelsToCompare(selectedLevels.length)}</p>
                            <ListWithLevels>
                                {filteredResults?.map((item) => {
                                    const currentSelectedLevel = selectedLevels.includes(`${item.talentPath.key}-${item.styrLevel.level}`);
                                    return <div key={item.talentPath.key + '-' + item.styrLevel.level} onClick={() => handleSelectedLevel(`${item.talentPath.key}-${item.styrLevel.level}`)}>
                                        <LevelOption label={item.styrLevel.level} isSelected={currentSelectedLevel} isCurrentLevel={item.styrLevel.level === currentLevel} />
                                    </div>
                                }
                                )}
                            </ListWithLevels>
                            {error && <ErrorText>{lang.pleaseSelectAtleastTwo}</ErrorText>}
                            <Navigation>
                                <BackButton text={`${lang.backToValidatedProfile}`} onClick={goBack} />
                                <Button
                                    text={lang.changeStyrLevel}
                                    priority="tertiary"
                                    onClick={close}
                                />
                            </Navigation>
                        </InnerPopup>

                    </CompareWithLevelPopUpContainer>
                </>
            ) : (
                <div onClick={open}>
                    {InnerContent && <InnerContent />}
                </div>
            )}
        </>
    );
};


const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CompareWithLevelPopUp);
