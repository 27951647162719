import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { Container } from './Documents.components';
import DocumentsProps from './Documents.types';
import ConnectedDocumentsBlocksSection from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.connector';

const Documents: React.FC<DocumentsProps> = ({
}) => {
    return (
        <MainLayout>
            <Container>
                <ConnectedDocumentsBlocksSection />
            </Container>
        </MainLayout>
    );
};

export default Documents;
