import React, { useEffect, useState } from 'react';
import ReduxStore from 'store/store.type';
import { resultChangeStatusBulk, resultsGetResults } from 'store/results/results.actions';
import { connect } from 'react-redux';
import ResultsFiltersProps from './ResultsFilters.types';
import FilterInputDropdown, {
    FilterForm,
    BulkActions,
} from './ResultsFilters.components';
import getLanguageObject from 'helpers/language';
import { options } from './ResultsFilters.data';
import BottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import { BaseFilterValues } from 'screens/Results/Results.types';
import { Status } from 'types/Result';
import { IconButton } from 'components/Button/IconButton/IconButton';
import RedoIcon from 'components/Icons/redo';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { CSVLink } from 'react-csv';
import PrintIcon from 'components/Icons/print';
import FormDropdown from 'containers/FormDropdown/FormDropdown';

export const getMostFrequentStatus = (array: { id: string, status: string }[]): Status => {
    type Acc = { maxCount: number; mostFrequent: string; } & Record<string, number>;
    return array.reduce((acc: Acc, item) => {
        acc[item.status] = acc[item.status] ? acc[item.status] + 1 : 1;
        if (acc[item.status] > acc.maxCount) {
            acc.maxCount = acc[item.status];
            acc.mostFrequent = item.status;
        }
        return acc;
    }, { maxCount: 0, mostFrequent: "" } as Acc).mostFrequent as Status;
}

const ResultsFilters: React.FC<ResultsFiltersProps> = ({
    setFilterValues,
    filterValues,
    results,
    currentLanguage,
    resultChangeStatusBulk,
    permissions,
    selectedItems,
    setSelectedItems,
    currentOrganisation,
    isShareLink
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [itemStatus, setItemStatus] = useState<Status>();
    const [filterOptions, setFilterOptions] = useState(options(currentLanguage, [], filterValues));

    useEffect(() => {
        setFilterOptions(options(currentLanguage, currentOrganisation?.organisationUnits || [], filterValues));


        if(filterValues.level.length === 0) {
            filterOptions.levelOption.forEach(x => x.shown = true);
            setFilterValues({
                ...filterValues,
                level: filterOptions.levelOption.map(x => x.uuid)
            })
        }
        if(filterValues.organisationUnit.length === 0) {
            filterOptions.organisationUnitOption.forEach(x => x.shown = true);
            setFilterValues({
                ...filterValues,
                organisationUnit: filterOptions.organisationUnitOption.map(x => x.uuid)
            })
        }
        if(filterValues.unit.length === 0) {
            filterOptions.unitOption.forEach(x => x.shown = true);
            setFilterValues({
                ...filterValues,
                unit: filterOptions.unitOption.map(x => x.uuid)
            })
        }
        if(filterValues.talentPath.length === 0) {
            filterOptions.talentPathOption.forEach(x => x.shown = true);
            setFilterValues({
                ...filterValues,
                talentPath: filterOptions.talentPathOption.map(x => x.uuid)
            })
        }

    }, [currentLanguage, currentOrganisation, filterValues]);


    const handleChange = (key: string, value: string | number) => {
        setFilterValues({
            ...filterValues,
            [key]: value,
        });
    };

    const handleResetFilter = () => {
        setFilterValues(BaseFilterValues);
    }

    const onBulkChangeStatus = (newStatus: string) => {
        const allItems = selectedItems.map(x => x.id)
        resultChangeStatusBulk(allItems, newStatus as Status)
        setSelectedItems([])
    }

    const maybeRenderBulkChangeUpdateStatus = () => {
        if (permissions.canUpdateStatusResult && selectedItems.length !== 0) return <>
            <FilterInputDropdown
                label="Status"
                onChange={e => {
                    const newStatus = e.target.value;
                    if (newStatus === "Show All") {
                        setItemStatus("" as Status);
                        return;
                    }

                    onBulkChangeStatus(newStatus);
                }}
                options={options(currentLanguage, [], undefined).status}
                selected={itemStatus || getMostFrequentStatus(selectedItems)}
            />
        </>

        return <FilterInputDropdown
            label="Status"
            onChange={e => {
                handleChange('status', e.target.value);
            }}
            options={options(currentLanguage, [], undefined).status}
            selected={filterValues.status}
        />;
    }

    const getCSVData = () => {
        return results.map(result => {
            const orgUnit = currentOrganisation?.organisationUnits.find(x => x.id === result.organisationUnitId)
            const unit = orgUnit?.units.find(x => x.id === result.unitId)
            return {
                name: result.name,
                level: result.validatedProfile.styrLevel.level,
                // Date only date without time
                date: new Date(result.createdOn).toLocaleDateString(),
                status: result.status,
                "talent path": getStringFromCurrentLanguage(result.validatedProfile.talentPath.names, currentLanguage),
                "organisation unit": getStringFromCurrentLanguage(orgUnit?.name, currentLanguage),
                unit: getStringFromCurrentLanguage(unit?.name, currentLanguage)
            }
        })
    }

    const changeLevels = (uuid: string) => {
        const isShown = filterValues.level.includes(uuid);
        setFilterValues({
            ...filterValues,
            level: isShown ? filterValues.level.filter(x => x !== uuid) : [...filterValues.level, uuid]
        });
    }

    const changeOrganisationUnits = (uuid: string) => {
        const isShown = filterValues.organisationUnit.includes(uuid);
        setFilterValues({
            ...filterValues,
            organisationUnit: isShown ? filterValues.organisationUnit.filter(x => x !== uuid) : [...filterValues.organisationUnit, uuid]
        });
    }

    const changeUnits = (uuid: string) => {
        const isShown = filterValues.unit.includes(uuid);
        setFilterValues({
            ...filterValues,
            unit: isShown ? filterValues.unit.filter(x => x !== uuid) : [...filterValues.unit, uuid]
        });
    }

    const changeTalentpaths = (uuid: string) => {
        const isShown = filterValues.talentPath.includes(uuid);
        setFilterValues({
            ...filterValues,
            talentPath: isShown ? filterValues.talentPath.filter(x => x !== uuid) : [...filterValues.talentPath, uuid]
        });
    }

    return (
        <BottomNavigation>
            <BulkActions>
                {maybeRenderBulkChangeUpdateStatus()}
                {!isShareLink && <CSVLink data={getCSVData()} filename="jobs.csv">
                    <IconButton color="warning" IconSVG={PrintIcon} onClick={() => { }} />
                </CSVLink>}
            </BulkActions>
            <FilterForm>
                <FormDropdown
                    label={lang.styrLevel}
                    onCheckBox={changeLevels}
                    options={filterOptions.levelOption}
                    currentLanguage={currentLanguage}
                    customWidth={130}
                    levels
                />
                <FormDropdown
                    label={lang.organisationUnit}
                    onCheckBox={changeOrganisationUnits}
                    options={filterOptions.organisationUnitOption}
                    currentLanguage={currentLanguage}
                    customWidth={240}
                />
                <FormDropdown
                    label={lang.unit}
                    onCheckBox={changeUnits}
                    options={filterOptions.unitOption}
                    currentLanguage={currentLanguage}
                    customWidth={240}
                />
                <FormDropdown
                    label={lang.talentpath}
                    onCheckBox={changeTalentpaths}
                    options={filterOptions.talentPathOption}
                    currentLanguage={currentLanguage}
                    customWidth={240}
                />
                <IconButton IconSVG={RedoIcon} onClick={handleResetFilter} color="warning" />
            </FilterForm>
        </BottomNavigation>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
    currentOrganisation: state.user.currentOrganisation,
    isShareLink: state.user.isShareLink
});
const mapDispatchToProps = {
    resultsGetResults,
    resultChangeStatusBulk
};
export default connect(mapStateToProps, mapDispatchToProps)(ResultsFilters);

