import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import Button from 'components/Button/Button';
import { H3, TextWarning } from 'components/Typography/Typography';
import { getValidatedProfileIcon } from 'helpers/getTalentpathIcon';
import getLanguageObject from 'helpers/language';
import React from 'react';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { StyrCompetency, ValidatedProfile } from 'types/Result';
import { Icon, ImageIcon, LevelEmblem, Table, Talentpath, TD, TH, VerticleText, VerticleTextCenter } from './ComparingLevelsTable.components';
import ComparingLevelsTableProps from './ComparingLevelsTable.types';
import theme from 'utils/theme';
import { getLevelIndex } from 'utils/levels';
import { transformTalentPathKey } from 'utils/talentPaths';

const ComparingLevelsTable: React.FC<ComparingLevelsTableProps> = ({ currentId, currentLevel, data, currentLanguage, currentOrganisation, selectedLevels, changeResultValidatedProfile, changePreviewValidatedProfile, }) => {
    const lang = getLanguageObject(currentLanguage);
    const tableData = data?.filter((item) => selectedLevels.includes(`${item.talentPath.key}-${item.styrLevel?.level}`)).sort((a, b) => {
        const levelA = a.styrLevel.level;
        const levelB = b.styrLevel.level;
        const indexLevelA = getLevelIndex(levelA)
        const indexLevelB = getLevelIndex(levelB)
        return indexLevelB - indexLevelA;
    });

    const changeValidatedProfileLevel = (validatedProfileLevel: string, validatedProfileId: string) => {
        if (changeResultValidatedProfile && currentId) return changeResultValidatedProfile(currentId, validatedProfileId);
        if (changePreviewValidatedProfile && validatedProfileLevel) return changePreviewValidatedProfile(validatedProfileLevel);
    }

    const decodeHtml = (html: string) => {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    }

    let tableDataByColumn: {
        uuid: string,
        title: string,
        itemKey: keyof ValidatedProfile
    }[] = [
            {
                uuid: "expertiseLevel",
                title: lang.expertiseLevel,
                itemKey: "expertiseLevel"
            },
            {
                uuid: "planningHorizon",
                title: lang.planningHorizon,
                itemKey: "planningHorizon"
            },
            {
                uuid: "continuousImprovement",
                title: lang.continuousImprovement,
                itemKey: "continuousImprovement"
            },
            {
                uuid: "autonomy",
                title: lang.autonomy,
                itemKey: "autonomy"
            },
            {
                uuid: "communicationSkills",
                title: lang.communicationSkills,
                itemKey: "communicationSkills"
            },
            {
                uuid: "managementSkills",
                title: lang.managementSkill,
                itemKey: "managementSkills"
            },
            {
                uuid: "projectManagementSkills",
                title: lang.projectManagementSkills,
                itemKey: "projectManagementSkills"
            },
            {
                uuid: "educationLevel",
                title: lang.educationLevelValidatedProfile,
                itemKey: "educationLevel"
            },
        ]

    const tableDataByColumnFiltered = tableDataByColumn.filter(x => {
        if (!tableData) {
            return false;
        }

        const textContents = tableData.map(item => {
            const stringTitle = getStringFromCurrentLanguage(
                //eslint-disable-next-line
                //@ts-ignore
                item[x.itemKey],
                currentLanguage
            )

            return Boolean(stringTitle)
        })

        // Filter out row if all texts are empty;
        const containsAtleastOneTrueString = textContents.find(booleanResult => {
            return booleanResult
        })

        return Boolean(containsAtleastOneTrueString);
    })

    const getCompetenceTitle = (competence: StyrCompetency) => {
        if (!competence || !competence.key) {
            return "";
        }
        //@ts-ignore
        return lang[competence.key] || competence.key;
    }

    const rowCount = tableData?.map(x => {
        const competences = x.competences || {};
        return Object.entries(competences).length
    }).reduceRight((max, current) => Math.max(max || 0, current || 0), 0) || 0

    if (!tableData) {
        return null;
    }
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <TH colSpan={2} style={{ padding: 0, backgroundColor: 'white' }}>
                        </TH>
                        {tableData?.map((item) => <TH key={item.id} center color={theme.colorCodePicker(item.color)}>
                            <LevelEmblem color={theme.colorCodePicker(item.color)}>{item.styrLevel.level}</LevelEmblem>
                        </TH>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TD colSpan={2} bold>
                            {lang.talentpath}
                        </TD>
                        {tableData?.map((item: ValidatedProfile) => <TD key={item.id} levelColor={item.color} currentLevel={`${item.talentPath.key}-${item.styrLevel.level}` === currentLevel ? true : false}>
                        <Talentpath>
                            <Icon>
                                    {item.talentPath.key && <ImageIcon src={getValidatedProfileIcon(item.talentPath.key)} />}
                                </Icon>
                                <H3>
                                    {transformTalentPathKey(item.talentPath.key, currentLanguage)}</H3>
                                    </Talentpath>
                        </TD>)}

                    </tr>
                    <tr>
                        <TD rowSpan={rowCount + (currentOrganisation.differentiatingFactors.styrCompetencies ? 10 : 9)} width={50}>
                            <VerticleTextCenter>
                                <VerticleText><strong>{lang.styrDifferencingFactors}</strong></VerticleText>
                            </VerticleTextCenter>
                        </TD>
                        <TD width={160} bold>
                            {lang.problemSolvingCapability}
                        </TD>
                        {tableData?.map((item: ValidatedProfile) => <TD key={item.id} levelColor={item.color} currentLevel={`${item.talentPath.key}-${item.styrLevel.level}` === currentLevel ? true : false}
                            dangerouslySetInnerHTML={{
                                __html:
                                    getStringFromCurrentLanguage(
                                        item.problemSolvingCapability,
                                        currentLanguage
                                    )
                            }} />
                        )}
                    </tr>
                    {tableDataByColumnFiltered.map(x => {
                        return (
                            <tr>
                                <TD bold>
                                    {x.title}
                                </TD>
                                {tableData?.map((item: ValidatedProfile) => <TD key={item.id} levelColor={item.color} currentLevel={`${item.talentPath.key}-${item.styrLevel.level}` === currentLevel ? true : false} dangerouslySetInnerHTML={{
                                    __html: getStringFromCurrentLanguage(
                                        //@ts-ignore
                                        item[x.itemKey],
                                        currentLanguage
                                    )
                                }} />)}
                            </tr>
                        )
                    })}
                    {(currentOrganisation?.differentiatingFactors.styrCompetencies) &&
                        <tr>
                            <TD rowSpan={rowCount} bold>
                                {lang.styr_competencies}
                            </TD>
                            {selectedLevels.map((_, columnIndex) => (
                                <TD rowSpan={1}
                                    key={tableData[columnIndex]?.id}
                                    levelColor={tableData[columnIndex]?.color}
                                    currentLevel={`${tableData[columnIndex]?.talentPath.key}-${tableData[columnIndex]?.styrLevel.level}` === currentLevel ? true : false}>
                                    <strong>{getCompetenceTitle(tableData[columnIndex]?.competences[0])}</strong>
                                    <br />
                                    {decodeHtml(getStringFromCurrentLanguage(tableData[columnIndex]?.competences[0].translations, currentLanguage))}
                                </TD>
                            ))}
                        </tr>
                    }
                    {(currentOrganisation?.differentiatingFactors.styrCompetencies) &&
                        Array.from({ length: rowCount - 1 }).map((_, rowIndex) => (
                            <tr key={rowIndex + 1}>
                                {selectedLevels.map((_, columnIndex) => (
                                    <TD
                                        rowSpan={1}
                                        key={tableData[columnIndex]?.id}
                                        levelColor={tableData[columnIndex]?.color}
                                        currentLevel={`${tableData[columnIndex]?.talentPath.key}-${tableData[columnIndex]?.styrLevel.level}` === currentLevel ? true : false}>
                                        <strong>{getCompetenceTitle(tableData[columnIndex]?.competences[rowIndex + 1])}</strong>
                                        <br />
                                        {decodeHtml(getStringFromCurrentLanguage(tableData[columnIndex]?.competences[rowIndex + 1]?.translations ?? "", currentLanguage))}
                                    </TD>
                                ))}
                            </tr>
                        ))
                    }
                    <tr>
                        <TD />
                        {tableData?.map((item: ValidatedProfile) => <TD key={item.id}
                            center>
                            {`${item.talentPath.key}-${item.styrLevel.level}` != currentLevel && (
                                <AreYouSurePopup
                                    onClick={() => changeValidatedProfileLevel(item.styrLevel.level, item.id)}
                                    action={lang.changing}
                                    item={lang.theStyrLevel}
                                    InnerContent={() => <Button center text={lang.changeLevel} />}
                                >
                                    <TextWarning>
                                        {lang.currentValidatedProfileLevelIs}
                                        {currentLevel}
                                        {lang.youreAboutToChange}
                                        {item.styrLevel.level}.
                                    </TextWarning>
                                </AreYouSurePopup>
                            )}
                        </TD>)}
                    </tr>
                </tbody>
            </Table>
        </>
    );

};



export default ComparingLevelsTable;
