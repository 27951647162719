import { DownloadTypes } from 'containers/MatrixBar/MatrixBar.types';
import jsPDF from 'jspdf';
import { toBlob } from 'html-to-image';
import { RefObject } from 'react';
import { formatJobFamilyImageFileName } from './formatImageDetails';
import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';

export const convertMatrixToFile = async (
    ref: RefObject<HTMLDivElement>,
    downloadType: DownloadTypes,
    instantDownload: boolean = true,
    matrixView?: MatrixViews,
    familyName?: string
): Promise<PromiseDownloadMatrixProps> => {
    try {
        if (!ref.current) {
            return null;
        }

        await new Promise(resolve => setTimeout(resolve, 5000));

        const blob = await toBlob(ref.current, { cacheBust: true });

        if (!blob) {
            return null;
        }

        const url = URL.createObjectURL(blob);
        const fileName = formatJobFamilyImageFileName(downloadType, undefined, matrixView, familyName);
        if (downloadType === DownloadTypes.Image) {
            if (instantDownload) {
                await handleImageDownload(url, fileName);
            }
            return { fileName, blob };
        } else if (downloadType === DownloadTypes.PDF) {
            await handlePdfDownload(url, fileName);
            return null;
        }

        return null;
    } catch (err) {
        return null;
    }
};

export const handleImageDownload = async (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
};

export const handlePdfDownload = async (imageUrl: string, fileName: string) => {
    try {
        const img = await loadImage(imageUrl);

        const imgWidth = img.width;
        const imgHeight = img.height;
        const pdf = new jsPDF({
            orientation: imgWidth > imgHeight ? 'l' : 'p',
            unit: 'px',
            format: [imgWidth, imgHeight],
        });

        pdf.addImage({
            imageData: imageUrl,
            format: 'PNG',
            compression: 'FAST',
            x: 0,
            y: 0,
            width: imgWidth,
            height: imgHeight,
        });
        pdf.save(fileName);
    } catch (error) {
        console.error('Error creating PDF:', error);
    } finally {
        URL.revokeObjectURL(imageUrl);
    }
};

const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = err => reject(new Error('Image loading failed'));
        img.src = src;
    });
};

export type PromiseDownloadMatrixProps = {
    fileName: string;
    blob: Blob;
} | null;
