import styled from 'styled-components';

export const Container = styled.div`
    height: calc(100vh - 119px);
    align-items: center;
    justify-content: center;
    margin-left: auto;

    @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        flex-direction: column;
    }
`;
