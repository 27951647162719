import { useNavigate } from 'helpers/useNavigation';
import React from 'react';
import { DocumentsBlockProps } from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.types';
import styled from 'styled-components';

export const DocumentsBlocksContainer = styled.article`
    width: 175px;
    min-width: 150px;
    max-width: 250px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(167 178 195 / 32%);
    overflow: hidden;
    flex: 0 0 20%;
    margin: 20px;

    cursor: pointer;

    &:nth-child(even) {background: #afc8e1}
    &:nth-child(odd) {background: #afc8e1}

    &:hover{
        &:nth-child(even) {background: #bfd3e7}
        &:nth-child(odd) {background: #bfd3e7}
    }
`

const DocumentsBlocksHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 120px;
`

const Figure = styled.figure`
    margin: 0;
    padding: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
`;

const DocumentsBlocksBody = styled.div`
    padding: 1.25em 1em;
    background-color: #fff;
    font-weight: bold;
    font-size: 0.9em;
    min-height: 35px;
`

const DocumentsBlocksImg = styled.img<{ enlarge?: boolean; }>`
    width: auto;
    height: 100px;
`;

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`

export const DocumentsBlocks: React.FC<DocumentsBlockProps> = ({ title, image, navigateToSite, navigateToPage }) => {
    const { navigate } = useNavigate();

    const goto = () => {
        if (navigateToSite) {
            window.open(navigateToSite, '_blank');
            return;
        } else if (navigateToPage) {
            navigate(navigateToPage);
        }
    }

    return (
        <DocumentsBlocksContainer onClick={goto}>
            <DocumentsBlocksHeader>
                <Figure>
                    <DocumentsBlocksImg
                        src={image}
                    />
                </Figure>
            </DocumentsBlocksHeader>
            <DocumentsBlocksBody>
                {title}
            </DocumentsBlocksBody>
        </DocumentsBlocksContainer >
    )
}

const Container = styled.div`
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
    background-color: white;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
`;


export const BlocksGrid: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (<Container>
        {children}
    </Container>)
}
