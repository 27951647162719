import Button, { BackButton } from 'components/Button/Button';
import CompareWithLevelPopUp from 'components/CompareWithLevelPopUp/CompareWithLevelPopUp';
import { H2 } from 'components/Typography/Typography';
import ConnectedComparingLevelsTable from 'containers/ComparingLevelsTable/ComparingLevelsTable.connector';
import getLanguageObject from 'helpers/language';
import React, { useState } from 'react';
import { ComparingLevelsSectionContainer, Header } from './ComparingLevels.components';
import ComparingLevelsProps from './ComparingLevels.types';
import { ValidatedProfile } from 'types/Result';

const ComparingLevels: React.FC<ComparingLevelsProps> = ({ id, result, preview, currentLanguage, currentOrganisation, changePreviewValidatedProfile, changeResultValidatedProfile, goBack, validatedProfiles }) => {
    const lang = getLanguageObject(currentLanguage);
    const [selectedLevels, setSelectedLevels] = useState([`${result?.validatedProfile.talentPath.key}-${result?.validatedProfile.styrLevel.level}`])

    const handleSelectedLevels = (level: string) => {
        if (level === `${result?.validatedProfile.talentPath.key}-${result?.validatedProfile.styrLevel.level}`) return;

        const checkInSelectedLevels = selectedLevels.includes(level);
        const checkSelectedLevels = selectedLevels.filter(
            (item) => item !== level
        );
        if (!checkInSelectedLevels && selectedLevels.length < 3) return setSelectedLevels([...selectedLevels, level]);

        return (setSelectedLevels(checkSelectedLevels));
    };

    if (currentOrganisation === undefined) return null;

    const ComparingLevelTables = () => {
        if (result) {
            return <ConnectedComparingLevelsTable
                currentId={id}
                    currentLevel={`${result.validatedProfile.talentPath.key}-${result.level.toString()}`}
                data={validatedProfiles}
                selectedLevels={selectedLevels}
                changeResultValidatedProfile={changeResultValidatedProfile}
                currentOrganisation={currentOrganisation} />
        }

        if (preview) {
            return <ConnectedComparingLevelsTable
                currentLevel={`${preview.talentPath.key}-${preview.styrLevel.level.toString()}`}
                data={validatedProfiles}
                selectedLevels={selectedLevels}
                changePreviewValidatedProfile={changePreviewValidatedProfile}
                currentOrganisation={currentOrganisation} />
        }
        return null;
    }
    return (
        <ComparingLevelsSectionContainer>
            <Header>
                <BackButton text={`${lang.backToValidatedProfile}`} onClick={goBack} />
                <H2>{lang.talentpath}</H2>
                <CompareWithLevelPopUp
                    results={validatedProfiles}
                    InnerContent={() => <Button text={lang.changeStyrLevel} />}
                    selectedLevels={selectedLevels}
                    handleSelectedLevel={handleSelectedLevels}
                    goBack={goBack}
                    currentTalentpath={result?.validatedProfile.talentPath.key}
                />
            </Header>
            <ComparingLevelTables />
        </ComparingLevelsSectionContainer>
    );
};

export default ComparingLevels;
