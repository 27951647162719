import ArrowRightIcon from "components/Icons/arrowRight";
import { H1 } from "components/Typography/Typography";
import getLanguageObject, { LangObject } from "helpers/language";
import React, { Fragment } from "react";
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import styled from "styled-components";
import { Language } from "types/MultiLingualString";

const AllocatorBreadCrumbs: React.FC<BreadcrumbProps> = ({ currentLanguage, path }) => {
    const lang: LangObject = getLanguageObject(currentLanguage);

    const decodeHtml = (html: string) => {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    }

    return (
        <Container>
            <H1>{lang.styrAllocator}</H1>
            <Content>
                <span>{lang.talentpath}</span>
                {path.map((x) => (
                    <Fragment key={x}>
                        <ArrowRightIcon color="#000" />
                        <span>{decodeHtml(x)}</span>
                    </Fragment>
                ))}
            </Content>
        </Container>
    )
}

const mapStateToProps = (state: ReduxStore) => ({ currentLanguage: state.language.currentLanguage });
const mapDispatchToProps = {};
const ConnectedAllocatorBreadCrumbs = connect(mapStateToProps, mapDispatchToProps)(AllocatorBreadCrumbs);

export default ConnectedAllocatorBreadCrumbs;

const Container = styled.div`
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    h1 {
        margin: 0;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`

interface BreadcrumbProps {
    currentLanguage: Language
    path: string[]
}
