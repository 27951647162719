import Button from 'components/Button/Button'
import { IconButton } from 'components/Button/IconButton/IconButton'
import Card from 'components/Card/Card'
import NumberTag from 'components/NumberTag/NumberTag'
import Popup, { Overlay } from 'components/Popup/Popup'
import { H1, H2 } from 'components/Typography/Typography'
import BottomNavigation from 'containers/BottomNavigation/BottomNavigation'
import { FormDropdownCheckListEmpty } from 'containers/FormDropdown/FormDropdown'
import ListItemWithToggle from 'containers/ListItemWithToggle/ListItemWithToggle'
import {
    Matrix,
    PrintingContainer,
    PrintingInnerContainer,
} from 'containers/MatrixContainer/MatrixContainer'
import useOnClickOutside from 'helpers/hooks/useOnClick'
import getLanguageObject from 'helpers/language'
import { useNavigate } from 'helpers/useNavigation'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { connect } from 'react-redux'
import Routes from 'routes/Routes.types'
import { TableZoomController } from 'screens/Matrix/Matrix.components'
import { AwaitIsPrinting } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.components'
import { getStringFromCurrentLanguage } from 'store/language/language.actions'
import { resultsUpdateResultByField } from 'store/results/results.actions'
import ReduxStore from 'store/store.type'
import { updateOrganisationRequest } from 'store/user/user.actions'
import { Language, MultiLingualString } from 'types/MultiLingualString'
import { Family, ValidatedProfile, Result, JobInformations } from 'types/Result'
import useLocalStorageState from 'use-local-storage-state'
import {
    constructGroupsFromLevels,
    getLevels,
    groupToColor,
} from 'utils/levels'
import {
    CompetenciesRows,
    DateHeaderContainer,
    DateNameContainer,
    DetailsColumns,
    DownloadListContainer,
    DownloadListItem,
    DownloadListNoItems,
    Footer,
    GroupCompetenceMatrixBox,
    GroupMatrixBox,
    GroupMatrixBoxColumns,
    GroupMatrixBoxTitle,
    InnerMatrixBox,
    JobFamilyDetails,
    JobFamilyHeaderTable,
    JobFamilyMatrixContainer,
    JobFamilyMatrixDropUp,
    JobFamilyMatrixDropUpContent,
    JobFamilyMatrixHeader,
    JobFamilySwitchView,
    Label,
    LastEditedByContainer,
    MatrixBox,
    MatrixDetails,
    MatrixLogo,
    StyrGroupInnerMatrixBox,
    StyrGroupMatrixBox,
    StyrLogo,
    Table,
    ToggleList,
} from './JobFamilyMatrix.styling'
import {
    DownloadTypes,
    FilterFields,
    JobFamilyMatrixStylingProps,
    RowProps,
    VerticalStickySetting,
} from './jobFamilyMatrix.types'
import {
    ConnectedMatrixBoxEditable,
    ConnectedMatrixBoxForm,
    ConnectedNewMatrixBoxForm,
    EditableFunctionOrRoleTitle,
    EditableStyrLevel,
    EditableStyrLevelTitle,
} from './MatrixBoxes'
import PrintIcon from 'components/Icons/print'
import EditIcon from 'components/Icons/edit'
import { DifferentiatingFactors, Organisation, OrganisationUser } from 'types/Organisation'
import { API_URL } from 'config'
import { Permissions } from 'store/user/user.types'
import ThumbTackIcon from 'components/Icons/thumbtack'
import {
    JobMatrixImageProps,
    useManageMatrixImage,
} from 'containers/MatrixBar/useManageMatrixImage'
import { TextField } from 'containers/InputField/InputField.components'
import DownloadIcon from 'components/Icons/download'
import { formatImageVersion } from 'helpers/formatImageDetails'
import CalendarTime from 'components/Icons/calendarTime'
import TrashIcon from 'components/Icons/trash'
import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup'

export const differentiatingFactors = (
    currentLanguage: Language,
    resultsInCurrentFamily: Result[],
    visibilityDifferentiatingFactors?: DifferentiatingFactors
) => {
    const lang = getLanguageObject(currentLanguage)
    if (!visibilityDifferentiatingFactors) return []

    const hasValues = (field: keyof ValidatedProfile) => {
        return resultsInCurrentFamily.some(x => {
            const value = getStringFromCurrentLanguage(
                x.validatedProfile[field] as MultiLingualString,
                currentLanguage
            )
            return value !== '' && value !== '<p><br></p>' && value !== null
        })
    }
    return [
        {
            title: lang.problemSolvingCapability,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.problemSolvingCapability
            ),
            hasValues: hasValues('problemSolvingCapability'),
            state: true,
        },
        {
            title: lang.expertiseLevel,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.expertiseLevel
            ),
            hasValues: hasValues('expertiseLevel'),
            state: visibilityDifferentiatingFactors.levelOfExpertise,
        },
        {
            title: lang.planningHorizon,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.planningHorizon
            ),
            hasValues: hasValues('planningHorizon'),
            state: visibilityDifferentiatingFactors.planningHorizon,
        },
        {
            title: lang.continuousImprovement,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.continuousImprovement
            ),
            hasValues: hasValues('continuousImprovement'),
            state: visibilityDifferentiatingFactors.continuousImprovement,
        },
        {
            title: lang.autonomy,
            data: resultsInCurrentFamily.map(x => x.validatedProfile.autonomy),
            hasValues: hasValues('autonomy'),
            state: visibilityDifferentiatingFactors.autonomy,
        },
        {
            title: lang.communicationSkills,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.communicationSkills
            ),
            hasValues: hasValues('communicationSkills'),
            state: visibilityDifferentiatingFactors.communicationSkills,
        },
        {
            title: lang.managementSkill,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.managementSkills
            ),
            hasValues: hasValues('managementSkills'),
            state: visibilityDifferentiatingFactors.coordinationSkills,
        },
        {
            title: lang.projectManagementSkills,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.projectManagementSkills
            ),
            hasValues: hasValues('projectManagementSkills'),
            state: visibilityDifferentiatingFactors.projectManagementSkills,
        },
        {
            title: lang.educationLevelValidatedProfile,
            data: resultsInCurrentFamily.map(
                x => x.validatedProfile.educationLevel
            ),
            hasValues: hasValues('educationLevel'),
            state: visibilityDifferentiatingFactors.educationLevel,
        },
    ]
}

export const RowStyrGroup: React.FC<RowProps> = ({
    results,
    levelOptions,
    currentLanguage,
    styling,
}) => {
    const showLevels = results.map(x => x.level)
    const levels = getLevels([])
    const groups = constructGroupsFromLevels(levels)

    return (
        <>
            <MatrixBox styling={styling}>
                <InnerMatrixBox />
            </MatrixBox>
            {groups
                .map((levels, i) => {
                    const rowSpan = showLevels.filter(x =>
                        levels.includes(x.toLowerCase())
                    ).length
                    if (rowSpan === 0) {
                        return null
                    }
                    //@ts-ignore
                    const title = getStringFromCurrentLanguage(
                        levelOptions?.['level_' + levels[0] as keyof typeof levelOptions]?.[
                        'problem_solving_focus'
                        ],
                        currentLanguage
                    )
                    const color = groupToColor(i)

                    return (
                        <StyrGroupMatrixBox
                            key={i + levels[0]}
                            rowSpan={rowSpan}
                        >
                            <StyrGroupInnerMatrixBox
                                color={color}
                                rowSpan={rowSpan}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </StyrGroupMatrixBox>
                    )
                })
                .reverse()}
        </>
    )
}

const mapRowStyrGroupStateToProps = (state: ReduxStore) => ({
    levelOptions: state.user.options.levels,
})

const mapRowStyrGroupDispatchToProps = { resultsUpdateResultByField }

export const ConnectedRowStyrGroup = connect(
    mapRowStyrGroupStateToProps,
    mapRowStyrGroupDispatchToProps
)(RowStyrGroup)

export const RowTalentpath: React.FC<RowProps> = ({
    results,
    currentLanguage,
    styling,
}) => {
    const lang = getLanguageObject(currentLanguage)

    return (
        <>
            <MatrixBox styling={styling}>
                <InnerMatrixBox bold>{lang.styrGroups}</InnerMatrixBox>
            </MatrixBox>
            {results.map(x => (
                <MatrixBox key={x.id + x.type}>
                    <InnerMatrixBox textCenter dangerouslySetInnerHTML={{
                        __html: getStringFromCurrentLanguage(
                            x.validatedProfile.styrGroup,
                            currentLanguage
                        )
                    }}>
                    </InnerMatrixBox>
                </MatrixBox>
            ))}
        </>
    )
}

function hexToRGB(hex: string | undefined, alpha: number) {
    if (!hex) {
        return 'rgb(255,255,255)'
    }

    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 1 + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export const RowLevel: React.FC<RowProps> = ({
    alternativeTitle,
    results,
    currentLanguage,
    family,
    styrLevelSub,
    organisationLevelsAvailable,
    styling,
    verticalStickySettings,
    updateVerticalSetting: setVerticalStickySettings
}) => {
    const lang = getLanguageObject(currentLanguage)

    if (!family) {
        return null
    }

    const localVerticalStickySetting = verticalStickySettings?.find(x => x.row === 0);
    if (!localVerticalStickySetting)
        return null;

    const localStyling = {
        ...styling,
        isVerticalSticky: localVerticalStickySetting?.enabled || false,
    }

    const changeVerticalSticky = () => {
        localVerticalStickySetting && setVerticalStickySettings && setVerticalStickySettings(localVerticalStickySetting);
    }

    // Renders the title column for the level row.
    const LevelRowTitleColumn = () => {
        if (organisationLevelsAvailable) {
            if (!alternativeTitle) {
                return null
            }
            return alternativeTitle
        }

        // If there is no alternative levels set in organisation level, we show styr level as title.
        // At the moment it is also possible to add alternative titles & levels on a per-family basis.
        // This might be removed in a future release.
        return (
            <>
                {lang.styrLevel}
                {family.alternativeLevelTitle
                    ? ' / ' + family.alternativeLevelTitle
                    : ''}
            </>
        )
    }

    // Renders an individual column per result in the job family matrix for the level row.
    const LevelRowColumn: React.FC<{ res: Result }> = ({ res }) => {
        if (!organisationLevelsAvailable) {
            return (
                <>
                    {res.level}
                    {res.alternativeLevel ? ' / ' + res.alternativeLevel : ''}
                </>
            )
        } else {
            return (
                <>
                    {styrLevelSub ? styrLevelSub[res.level?.toLowerCase()] : ''}
                </>
            )
        }
    }

    // What we render in normal view. When an alternative level description is provided, the same is rendered as this can not be editted.
    if (!localStyling.isEditMode || organisationLevelsAvailable) {
        return (
            <>
                <MatrixBox styling={
                    {
                        ...localStyling,
                        stickyHorizontalOffset: 0
                    }
                }>
                    <InnerMatrixBox bold>
                        <LevelRowTitleColumn />
                        <div className="horizontalSticky">
                            <IconButton
                                IconSVG={ThumbTackIcon}
                                onClick={() => changeVerticalSticky()}
                                color={!localVerticalStickySetting.enabled ? 'warning' : 'warningSticky'}
                            />
                        </div>
                    </InnerMatrixBox>
                </MatrixBox>
                {results.map(x => (
                    <MatrixBox
                        styling={
                            {
                                ...localStyling,
                                isHorizontalSticky: false
                            }
                        }
                        key={x.id + x.level}
                        style={{
                            backgroundColor: hexToRGB(
                                x.validatedProfile.color,
                                1
                            ),
                        }}
                    >
                        <InnerMatrixBox textCenter bold>
                            <LevelRowColumn res={x} />
                        </InnerMatrixBox>
                    </MatrixBox>
                ))}
            </>
        )
    }

    // What we render in edit view.
    return (
        <>
            <MatrixBox styling={
                {
                    ...localStyling,
                    stickyHorizontalOffset: 0
                }
            }>
                <InnerMatrixBox bold>
                    {lang.styrLevel}
                    {' / '}
                    <EditableStyrLevelTitle
                        id={family.id}
                        title={family?.alternativeLevelTitle}
                    />
                    <div className="horizontalSticky">
                        <IconButton
                            IconSVG={ThumbTackIcon}
                            onClick={() => changeVerticalSticky()}
                            color={!localVerticalStickySetting.enabled ? 'warning' : 'warningSticky'}
                        />
                        {localVerticalStickySetting.enabled ? 'Sticky' : 'not sticky'}
                    </div>
                </InnerMatrixBox>
            </MatrixBox>
            {results.map(x => (
                <MatrixBox
                    styling={
                        {
                            ...localStyling,
                            isHorizontalSticky: false
                        }
                    }
                    key={x.id + x.level}
                    style={{
                        backgroundColor: hexToRGB(x.validatedProfile.color, 1),
                    }}
                >
                    <InnerMatrixBox textCenter bold>
                        {x.level}
                        {' / '}
                        <EditableStyrLevel
                            id={x.id}
                            alternativeLevel={x.alternativeLevel}
                        />
                    </InnerMatrixBox>
                </MatrixBox>
            ))}
        </>
    )
}

// The Job/Role row of the family matrix.
export const RowJobRole: React.FC<RowProps> = ({
    results,
    currentLanguage,
    family,
    styling,
    verticalStickySettings,
    updateVerticalSetting: setVerticalStickySettings
}) => {
    if (!verticalStickySettings)
        return null;

    const lang = getLanguageObject(currentLanguage)

    const title =
        family &&
        getStringFromCurrentLanguage(
            family.functionOrRoleTitle,
            currentLanguage
        )

    const titleWithFallback = title || lang.functionOrRole;

    let localVerticalStickySetting = verticalStickySettings?.find(x => x.row === 1);
    if (!localVerticalStickySetting)
        return null;

    const [localStyling, setLocalStyling] = useState<JobFamilyMatrixStylingProps>({
        ...styling,
        stickyHorizontalOffset: 0,
        isVerticalSticky: localVerticalStickySetting.enabled || false
    });

    useEffect(() => {
        setLocalStyling({
            ...styling,
            isVerticalSticky: localVerticalStickySetting?.enabled || false,
            stickyVerticalOffset: localVerticalStickySetting?.offset || 0
        });
    }, [styling])

    useEffect(() => {
        localVerticalStickySetting = verticalStickySettings?.find(x => x.row === 1);
        if (!localVerticalStickySetting)
            return;

        setLocalStyling({
            ...styling,
            isVerticalSticky: localVerticalStickySetting.enabled || false,
            stickyVerticalOffset: localVerticalStickySetting.offset || 0
        });

    }, [verticalStickySettings])


    const changeVerticalSticky = () => {
        localVerticalStickySetting && setVerticalStickySettings && setVerticalStickySettings(localVerticalStickySetting)
    }

    return (
        <>
            <MatrixBox styling={localStyling}>
                <InnerMatrixBox bold>
                    {styling.isEditMode && family ? (
                        <EditableFunctionOrRoleTitle
                            id={family.id}
                            title={titleWithFallback}
                        />
                    ) : (
                        titleWithFallback
                    )}
                    <div className="horizontalSticky">
                        <IconButton
                            IconSVG={ThumbTackIcon}
                            onClick={() => changeVerticalSticky()}
                            color={!localVerticalStickySetting.enabled ? 'warning' : 'warningSticky'}
                        />
                    </div>
                </InnerMatrixBox>
            </MatrixBox>
            {results.map(x => (
                <MatrixBox
                    styling={
                        {
                            ...localStyling,
                            isHorizontalSticky: false
                        }
                    }
                    key={x.id + x.name}>
                    <InnerMatrixBox textCenter>{x.name}</InnerMatrixBox>
                </MatrixBox>
            ))}
        </>
    )
}

const getTitleOrReturnFallback = (
    title: MultiLingualString | string,
    currentLanguage: Language
) => {
    if (typeof title === 'string') {
        return title
    }
    const value = getStringFromCurrentLanguage(title, currentLanguage)
    if (value === '') return ''

    return value
}

const hasValues = (
    results: Result[],
    field: keyof JobInformations,
    editMode: boolean,
    currentLanguage: Language,
    title?: MultiLingualString
) => {
    if (editMode) return true

    if (title) {
        const hasTitle = Boolean(
            getStringFromCurrentLanguage(title, currentLanguage)
        )
        if (hasTitle) {
            return true
        }
    }

    return results.some(item => {
        if (!item?.jobInformations?.[field]) return false
        if (typeof item?.jobInformations?.[field] === 'string')
            return Boolean(item?.jobInformations?.[field])
        const jobInfoAsMultiLingualString = item?.jobInformations?.[
            field
        ] as MultiLingualString
        const value = getStringFromCurrentLanguage(
            jobInfoAsMultiLingualString,
            currentLanguage
        )
        if (value === '' || value === '<p><br></p>') return false
        return true
    })
}

export const RowEditableOptionals: React.FC<RowProps> = ({
    filter,
    family,
    results,
    currentLanguage,
    styling,
}) => {
    const lang = getLanguageObject(currentLanguage)

    if (!family) return null
    const localStyling = {
        ...styling
    }

    return (
        <>
            {hasValues(
                results,
                'jobFamily_Field_1',
                Boolean(styling.isEditMode),
                currentLanguage,
                family.freeInputField1Title
            ) &&
                filter?.jobFamily_Field_1 && (
                    <>
                        <ConnectedMatrixBoxEditable
                            id={family.id}
                            field={'freeInputField1Title'}
                            whenEmpty={lang.enterTitle + ' 1'}
                            placeholder={lang.enterTitle}
                            text={getTitleOrReturnFallback(
                                family.freeInputField1Title,
                                currentLanguage
                            )}
                            put='family'
                            bold
                            styling={localStyling}

                        />
                        {results.map((item, index) => {
                            return (
                                <ConnectedMatrixBoxEditable
                                    wysiwyg
                                    key={`jobFamily_Field_1` + index}
                                    id={item.id}
                                    placeholder={lang.enterDescription}
                                    field={'jobFamily_Field_1'}
                                    text={getStringFromCurrentLanguage(
                                        item.jobInformations?.jobFamily_Field_1,
                                        currentLanguage
                                    )}
                                    styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                                />
                            )
                        })}
                    </>
                )}
            {hasValues(
                results,
                'jobFamily_Field_2',
                Boolean(styling.isEditMode),
                currentLanguage,
                family.freeInputField2Title
            ) &&
                filter?.jobFamily_Field_2 && (
                    <>
                        <ConnectedMatrixBoxEditable
                            id={family.id}
                            field={'freeInputField2Title'}
                            whenEmpty={lang.enterTitle + ' 2'}
                            placeholder={lang.enterTitle}
                            text={getTitleOrReturnFallback(
                                family.freeInputField2Title,
                                currentLanguage
                            )}
                            put='family'
                            bold
                            styling={localStyling}
                        />
                        {results.map((item, index) => {
                            return (
                                <ConnectedMatrixBoxEditable
                                    wysiwyg
                                    key={`jobFamily_Field_2` + index}
                                    id={item.id}
                                    placeholder={lang.enterDescription}
                                    field={'jobFamily_Field_2'}
                                    text={getStringFromCurrentLanguage(
                                        item.jobInformations?.jobFamily_Field_2,
                                        currentLanguage
                                    )}
                                    styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                                />
                            )
                        })}
                    </>
                )}
            {hasValues(
                results,
                'jobFamily_Field_3',
                Boolean(styling.isEditMode),
                currentLanguage,
                family.freeInputField3Title
            ) &&
                filter?.jobFamily_Field_3 && (
                    <>
                        <ConnectedMatrixBoxEditable
                            id={family.id}
                            field={'freeInputField3Title'}
                            whenEmpty={lang.enterTitle + ' 3'}
                            placeholder={lang.enterTitle}
                            text={getTitleOrReturnFallback(
                                family.freeInputField3Title,
                                currentLanguage
                            )}
                            put='family'
                            bold
                            styling={localStyling}
                        />
                        {results.map((item, index) => {
                            return (
                                <ConnectedMatrixBoxEditable
                                    wysiwyg
                                    key={`jobFamily_Field_3` + index}
                                    id={item.id}
                                    placeholder={lang.enterDescription}
                                    field={'jobFamily_Field_3'}
                                    text={getStringFromCurrentLanguage(
                                        item.jobInformations?.jobFamily_Field_3,
                                        currentLanguage
                                    )}
                                    styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                                />
                            )
                        })}
                    </>
                )}
            {hasValues(
                results,
                'jobFamily_Field_4',
                Boolean(styling.isEditMode),
                currentLanguage,
                family.freeInputField4Title
            ) &&
                filter?.jobFamily_Field_4 && (
                    <>
                        <ConnectedMatrixBoxEditable
                            id={family.id}
                            field={'freeInputField4Title'}
                            whenEmpty={lang.enterTitle + ' 4'}
                            placeholder={lang.enterTitle}
                            text={getTitleOrReturnFallback(
                                family.freeInputField4Title,
                                currentLanguage
                            )}
                            put='family'
                            bold
                            styling={localStyling}
                        />
                        {results.map((item, index) => {
                            return (
                                <ConnectedMatrixBoxEditable
                                    wysiwyg
                                    key={`jobFamily_Field_4` + index}
                                    id={item.id}
                                    placeholder={lang.enterDescription}
                                    field={'jobFamily_Field_4'}
                                    text={getStringFromCurrentLanguage(
                                        item.jobInformations?.jobFamily_Field_4,
                                        currentLanguage
                                    )}
                                    styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                                />
                            )
                        })}
                    </>
                )}
            {hasValues(
                results,
                'jobFamily_Field_5',
                Boolean(styling.isEditMode),
                currentLanguage,
                family.freeInputField5Title
            ) &&
                filter?.jobFamily_Field_5 && (
                    <>
                        <ConnectedMatrixBoxEditable
                            id={family.id}
                            field={'freeInputField5Title'}
                            whenEmpty={lang.enterTitle + ' 5'}
                            placeholder={lang.enterTitle}
                            text={getTitleOrReturnFallback(
                                family.freeInputField5Title,
                                currentLanguage
                            )}
                            put='family'
                            bold
                            styling={localStyling}
                        />
                        {results.map((item, index) => {
                            return (
                                <ConnectedMatrixBoxEditable
                                    wysiwyg
                                    key={`jobFamily_Field_5` + index}
                                    id={item.id}
                                    placeholder={lang.enterDescription}
                                    field={'jobFamily_Field_5'}
                                    text={getStringFromCurrentLanguage(
                                        item.jobInformations?.jobFamily_Field_5,
                                        currentLanguage
                                    )}
                                    styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                                />
                            )
                        })}
                    </>
                )}
        </>
    )
}

export const RowsDifferentiatingFactors: React.FC<RowProps> = ({
    family,
    filter,
    results,
    currentLanguage,
    currentOrganisation,
    styling,
}) => {
    const lang = getLanguageObject(currentLanguage)

    const visibilityDifferentiatingFactors =
        currentOrganisation?.differentiatingFactors
    if (!family) return null

    const localStyling = {
        ...styling
    }

    return (
        <GroupMatrixBox columns={results.length + 1}>
            <GroupMatrixBoxTitle styling={localStyling}>
                <div>
                    <strong>{lang.differentiatingFactorsTitle}</strong>
                </div>
            </GroupMatrixBoxTitle>
            <GroupMatrixBoxColumns columns={results.length}>
                {differentiatingFactors(
                    currentLanguage,
                    results,
                    visibilityDifferentiatingFactors
                ).map((factor, i) => {
                    if (!factor.state) return null
                    if (!factor.hasValues) return null
                    return (
                        <>
                            <MatrixBox
                                key={factor.title + i}
                                styling={{ ...localStyling, stickyHorizontalOffset: 66 }}
                            >
                                <InnerMatrixBox bold>
                                    {factor.title}
                                </InnerMatrixBox>
                            </MatrixBox>
                            {factor.data.map((item, index) => {
                                return (
                                    <MatrixBox key={factor.title + index}>
                                        <InnerMatrixBox
                                            dangerouslySetInnerHTML={{
                                                __html: getStringFromCurrentLanguage(
                                                    item,
                                                    currentLanguage
                                                ),
                                            }}
                                        />
                                    </MatrixBox>
                                )
                            })}
                        </>
                    )
                })}
                {hasValues(
                    results,
                    'jobFamily_Field_6',
                    Boolean(styling.isEditMode),
                    currentLanguage,
                    family.freeInputField6Title
                ) &&
                    filter?.jobFamily_Field_6 && (
                        // stickyOffset contains the width 66 because that is the width if the header title + the boxshadow
                        <>
                            <ConnectedMatrixBoxEditable
                                id={family.id}
                                field={'freeInputField6Title'}
                                whenEmpty={lang.enterTitle + ' 6'}
                                placeholder={lang.enterTitle}
                                text={getTitleOrReturnFallback(
                                    family.freeInputField6Title,
                                    currentLanguage
                                )}
                                put='family'
                                bold
                                styling={{ ...localStyling, stickyHorizontalOffset: 66 }}
                            />
                            {results.map((item, index) => {
                                return (
                                    <ConnectedMatrixBoxEditable
                                        wysiwyg
                                        key={`jobFamily_Field_6` + index}
                                        placeholder={lang.enterDescription}
                                        id={item.id}
                                        field={'jobFamily_Field_6'}
                                        text={getTitleOrReturnFallback(
                                            item?.jobInformations
                                                ?.jobFamily_Field_6 ?? '',
                                            currentLanguage
                                        )}
                                        styling={{
                                            ...localStyling,
                                            isHorizontalSticky: false,
                                            isVerticalSticky: false
                                            , stickyHorizontalOffset: 66
                                        }}
                                    />
                                )
                            })}
                        </>
                    )}
            </GroupMatrixBoxColumns>
        </GroupMatrixBox>
    )
}

const RowNavigateTo: React.FC<{
    filter?: FilterFields
    currentLanguage: Language
    results: Result[]
    styling: JobFamilyMatrixStylingProps
}> = ({ currentLanguage, results, styling }) => {
    const { navigate } = useNavigate()
    const lang = getLanguageObject(currentLanguage)
    const localStyling = {
        ...styling
    }
    return (
        <>
            <MatrixBox styling={{ ...localStyling, stickyHorizontalOffset: 0 }}>
                <InnerMatrixBox bold></InnerMatrixBox>
            </MatrixBox>
            {results.map(x => (
                <MatrixBox key={x.id + x.type}>
                    <InnerMatrixBox textCenter>
                        {x.status === 'active' && (
                            <Button
                                text={lang.viewJobMatrix}
                                priority='tertiary'
                                onClick={() =>
                                    navigate(
                                        `${Routes.JobMatrixRoute}?id=${x.id}`
                                    )
                                }
                            />
                        )}
                    </InnerMatrixBox>
                </MatrixBox>
            ))}
        </>
    )
}

const StyrCompetenceColumn: React.FC<{
    rowCount: number
    currentLanguage: Language
    data: Result
    styling: JobFamilyMatrixStylingProps
}> = ({ rowCount, currentLanguage, data, styling }) => {
    const lang = getLanguageObject(currentLanguage)
    const competences = data.validatedProfile.competences || []
    const emptyRows = rowCount - competences.length
    const localStyling = {
        ...styling
    }
    return (
        <>
            {competences.map(c => {
                //@ts-ignore
                const title = lang[c.key] || c.key
                return (
                    <MatrixBox key={`StyrCompetence` + c.key} styling={localStyling}>
                        <InnerMatrixBox>
                            <div>
                                <strong>{title}</strong>
                                <br />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: getStringFromCurrentLanguage(
                                            c.translations,
                                            currentLanguage
                                        ),
                                    }}
                                />
                            </div>
                        </InnerMatrixBox>
                    </MatrixBox>
                )
            })}
            {Array(emptyRows)
                .fill(null)
                .map(() => (
                    <div style={{ background: 'white' }}></div>
                ))}
        </>
    )
}

const RenderExtraCompetence: React.FC<{
    results: Result[]
    currentLanguage: Language
    styling: JobFamilyMatrixStylingProps
}> = ({ results, currentLanguage, styling }) => {
    const lang = getLanguageObject(currentLanguage)
    const rowCount =
        results
            .map(x => x.jobCompetences?.length)
            .reduceRight(
                (max, current) => Math.max(max || 0, current || 0),
                0
            ) || 0

    if (rowCount === 0 && !styling.isEditMode) return null

    if (styling.isHorizontalSticky) {
        // stickyOffset contains the width 66 because that is the width if the header title + the boxshadow
        styling.stickyHorizontalOffset = 66
    }

    return (
        <GroupCompetenceMatrixBox rowCount={rowCount} columns={results.length}>
            <MatrixBox
                style={{ gridRow: 'span ' + rowCount }}
                styling={styling}
            >
                <InnerMatrixBox bold>{lang.extraCompetencies}</InnerMatrixBox>
            </MatrixBox>
            {results.map((column, index) => (
                <ExtraCompetenceColumn
                    rowCount={rowCount}
                    key={`ExtraCompetenceColumn` + index}
                    data={column}
                    currentLanguage={currentLanguage}
                    editMode={Boolean(styling.isEditMode)}
                />
            ))}
        </GroupCompetenceMatrixBox>
    )
}

const ExtraCompetenceColumn: React.FC<{
    rowCount: number
    data: Result
    currentLanguage: Language
    editMode: boolean
}> = ({ rowCount, data, currentLanguage, editMode }) => {
    if (!data.jobCompetences || data.jobCompetences.length === 0) {
        return (
            <>
                {Array(rowCount)
                    .fill(null)
                    .map(() => (
                        <div></div>
                    ))}
            </>
        )
    }

    const emptyRows = rowCount - data.jobCompetences.length

    return (
        <>
            {data.jobCompetences.map((item, index) => {
                return (
                    <ConnectedMatrixBoxForm
                        key={`ExtraCompetence` + index}
                        position={index}
                        totalItemCount={data.jobCompetences?.length}
                        id={data.id}
                        title={getStringFromCurrentLanguage(
                            item.title,
                            currentLanguage
                        )}
                        text={getStringFromCurrentLanguage(
                            item.text,
                            currentLanguage
                        )}
                        editMode={editMode}
                        canDelete
                    />
                )
            })}
            {Array(emptyRows)
                .fill(null)
                .map(() => (
                    <div></div>
                ))}
        </>
    )
}

export const RowsStyrCompetences: React.FC<RowProps> = ({
    filter,
    results,
    currentLanguage,
    currentOrganisation,
    styling,
}) => {
    const lang = getLanguageObject(currentLanguage)
    const rowCount =
        results
            .map(x => {
                const competences = x.validatedProfile.competences || {}
                return Object.entries(competences).length
            })
            .reduceRight(
                (max, current) => Math.max(max || 0, current || 0),
                0
            ) || 0

    if (
        !currentOrganisation?.differentiatingFactors?.styrCompetencies &&
        !filter?.jobCompetences
    ) {
        return null
    }

    const localStyling = {
        ...styling
    }

    const RenderStyrCompetence = () => {
        if (!currentOrganisation?.differentiatingFactors?.styrCompetencies) {
            return null
        }

        return (
            <>
                <GroupCompetenceMatrixBox
                    rowCount={rowCount}
                    columns={results.length}
                >
                    <InnerMatrixBox
                        color='#fff'
                        style={{ gridRow: 'span ' + rowCount }}
                        styling={{ ...localStyling, stickyHorizontalOffset: 66 }}
                    >
                        <div>
                            <strong>{lang.defaultStyrCompetencies}</strong>
                        </div>
                    </InnerMatrixBox>
                    {results.map((column, index) => (
                        <StyrCompetenceColumn
                            rowCount={rowCount}
                            currentLanguage={currentLanguage}
                            key={`StyrCompetences` + index}
                            data={column}
                            styling={{ ...localStyling, isHorizontalSticky: false, isVerticalSticky: false }}
                        />
                    ))}
                </GroupCompetenceMatrixBox>
            </>
        )
    }

    const hasAnyJobCompetences = results.some(
        x => x.jobCompetences?.length ?? 0 > 0
    )

    const rowsCount =
        hasAnyJobCompetences ||
        currentOrganisation?.differentiatingFactors?.styrCompetencies
    if (!rowsCount && !styling.isEditMode) {
        return null
    }

    return (
        <>
            <GroupMatrixBox columns={results.length + 1}>
                <GroupMatrixBoxTitle styling={localStyling}>
                    <div>
                        <strong>{lang.competencies}</strong>
                    </div>
                </GroupMatrixBoxTitle>
                <CompetenciesRows
                    hasExtraCompetences={
                        hasAnyJobCompetences && filter?.jobCompetences
                    }
                    rowsCount={rowsCount}
                >
                    {currentOrganisation?.differentiatingFactors
                        ?.styrCompetencies && <RenderStyrCompetence />}
                    {filter?.jobCompetences && (
                        <RenderExtraCompetence
                            currentLanguage={currentLanguage}
                            results={results}
                            styling={localStyling}
                        />
                    )}
                </CompetenciesRows>
            </GroupMatrixBox>
            {!localStyling.isPrinting &&
                localStyling.isEditMode &&
                filter?.jobCompetences && (
                    <>
                        <MatrixBox naked />
                        {results.map((item, index) => (
                            <ConnectedNewMatrixBoxForm
                                key={`New ExtraCompetence` + index}
                                id={item.id}
                            />
                        ))}
                    </>
                )}
        </>
    )
}

const mapRowsStyrCompetencesStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    validatedProfiles: state.allocator.validatedProfiles,
})

const mapRowsStyrCompetencesDispatchToProps = {}

export const ConnectedRowsStyrCompetences = connect(
    mapRowsStyrCompetencesStateToProps,
    mapRowsStyrCompetencesDispatchToProps
)(RowsStyrCompetences)

export const ManageJobFamilyPopup: React.FC<{
    results: Result[]
    family: Family
    options: FilterFields
    setOptions: (value: FilterFields) => void
    handlePopup: () => void
    currentLanguage: Language
    editMode: boolean
}> = ({
    results,
    family,
    options,
    setOptions,
    handlePopup,
    currentLanguage,
    editMode,
}) => {
        const lang = getLanguageObject(currentLanguage)
        const ref = useRef(null)
        useOnClickOutside(ref, () => close())
        const close = () => {
            handlePopup()
        }

        const changeState = (key: string, value: boolean) => {
            setOptions({
                ...options,
                [key]: !value,
            })
        }

        return (
            <Overlay>
                <Card>
                    <div ref={ref}>
                        <H2>{lang.manageRows}</H2>
                        <ToggleList>
                            {Object.entries(options).map(
                                ([key, value]: any, index) => {
                                    let lookupTitle = ''
                                    if (key.startsWith('jobFamily_Field_')) {
                                        let index = key.split('_')[2]
                                        //@ts-ignore
                                        let title =
                                            (family as any)[`freeInputField${index}Title`]
                                        lookupTitle = getTitleOrReturnFallback(
                                            title,
                                            currentLanguage
                                        )
                                    }

                                    if (key === 'jobCompetences') {
                                        lookupTitle = lang.extraCompetencies
                                    }

                                    // In viewing mode, remove options with no values or title
                                    if (
                                        !editMode &&
                                        (lookupTitle === '' ||
                                            !hasValues(
                                                results,
                                                key,
                                                editMode,
                                                currentLanguage
                                            ))
                                    ) {
                                        return
                                    }

                                    return (
                                        <div
                                            onClick={() => changeState(key, value)}
                                        >
                                            <ListItemWithToggle
                                                label={
                                                    lookupTitle ||
                                                    lang.enterTitle + ' ' + index
                                                }
                                                state={value}
                                            />
                                        </div>
                                    )
                                }
                            )}
                        </ToggleList>
                    </div>
                </Card>
            </Overlay>
        )
    }
export const FamilyMatrix: React.FC<{
    permissions: Permissions
    isShareLink?: boolean
    currentOrganisation?: Organisation
    jobfamily: Family
    currentLanguage: Language
    sortedResultsInCurrentFamily: Result[],
    users?: OrganisationUser[]
}> = ({
    permissions,
    sortedResultsInCurrentFamily,
    currentLanguage,
    currentOrganisation,
    jobfamily,
    isShareLink,
    users
}) => {
        const ref = useRef<HTMLDivElement>(null)

        // This is a hook that is used to manage the image versions of the job matrix.
        // It is used to create new versions of the image and download existing versions.
        const {
            printing,
            familyMatrixImages,
            loadingFamilyMatrixImages,
            handleFamilyMatrixImagesPopup,
            handleCreateNewVersion,
            handleDownloadImageVersion,
            handleDeleteImageVersion,
            handleImageDescription,
            handleDownloadMatrix,
        } = useManageMatrixImage({
            currentOrganisation,
            ref,
            jobfamily,
        })

        const lang = getLanguageObject(currentLanguage)

        const resultIDs: string[] = sortedResultsInCurrentFamily.map(x => x.id)
        const [showResults, setShowResults] = useState<string[]>(resultIDs)
        const [visibleJobFamilyOptions, setVisibleJobFamilyOptions] = useState(
            false
        )

        const [editMode, setEditMode] = useState<boolean>(false)
        const [horizontalSticky, setHorizontalSticky] = useState<boolean>(false)
        const [verticalStickySettings, setVerticalStickySettings] = useState<VerticalStickySetting[]>([
            { enabled: false, row: 0, offset: undefined },
            { enabled: false, row: 1, offset: undefined },
        ])

        const updateVerticalStickySettings = (updatedSetting: VerticalStickySetting) => {
            updatedSetting.enabled = !updatedSetting.enabled;

            setVerticalStickySettings(verticalStickySettings.map(x => {
                if (x.row === updatedSetting.row)
                    return updatedSetting
                if (x.row > updatedSetting.row) {
                    if (updatedSetting.enabled)
                        return {
                            ...x,
                            offset: (x.offset || 0) + 90
                        }
                    return {
                        ...x,
                        offset: (x.offset || 0) - 90
                    }
                }
                return x;
            }));
        };


        const [zoomTable, handleZoom] = useLocalStorageState<number>(
            'job-family-matrix-zoom'
        )
        const [filterFields, setFilterFields] = useLocalStorageState(
            'filter-fields-2-' + currentOrganisation?.id,
            {
                jobFamily_Field_1: true,
                jobFamily_Field_2: true,
                jobFamily_Field_3: true,
                jobFamily_Field_4: true,
                jobFamily_Field_5: true,
                jobFamily_Field_6: true,
                jobCompetences: true,
            }
        )

        // Update when resultIDs updates.
        useEffect(() => {
            if (showResults.length > 0) return
            setShowResults(resultIDs)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [sortedResultsInCurrentFamily])

        const primaryDescription = getStringFromCurrentLanguage(
            jobfamily.primaryDescription,
            currentLanguage
        )
        const secondaryDescription = getStringFromCurrentLanguage(
            jobfamily.secondaryDescription,
            currentLanguage
        )

        const handleVisibilityManager = () =>
            setVisibleJobFamilyOptions(!visibleJobFamilyOptions)
        const changeDisplayMode = () => {
            setEditMode((current: boolean) => !current)
            updateOrganisationRequest()
            return
        }

        const changeHorizontalStickyMode = () => {
            setHorizontalSticky((current: boolean) => !current)
            return
        }

        const showDescriptionFields = () => {
            if (editMode) return true
            if (primaryDescription === '' && secondaryDescription === '')
                return false
            return true
        }

        const getName = () => {
            if (!jobfamily.lastModifiedBy || jobfamily.lastModifiedBy.includes('@') || jobfamily.lastModifiedBy === "OrganisationImporter") {
                return null;
            }

            const user = users?.find(u => u.objectId === jobfamily.lastModifiedBy || u.id === jobfamily.lastModifiedBy);

            if (!user) {
                return null;
            }

            if (user.firstName || user.lastName) {
                return `${lang.updatedBy} ${user.firstName} ${user.lastName}`;
            }
            return `${lang.updatedBy} ${user.emailAdress.split("@")[0]}`;
        }

        const DateHeader = () => {
            var today = moment().format('DD / MM / YYYY');
            return (
                <DateNameContainer>
                    <DateHeaderContainer>{today}</DateHeaderContainer>
                    {!getName()?.toLowerCase().includes("unknown") && <LastEditedByContainer>{getName()}</LastEditedByContainer>}
                </DateNameContainer>)
        }

        const changeVisibilityLevel = (id: string) => {
            const resultIsShown = showResults.includes(id)
            if (resultIsShown)
                return setShowResults(current => current.filter(x => x !== id))

            return setShowResults([...showResults, id])
        }

        const showTheseResults = sortedResultsInCurrentFamily.filter(x =>
            showResults.includes(x.id)
        )
        const cssStylingScrollContainer = printing
            ? { height: 'unset', width: '100%', background: '#fff' }
            : { height: '100%', maxHeight: 'calc(100vh - 280px)', width: `100%` }

        const canEdit =
            permissions.canUpdateStatusResult ||
            jobfamily.status?.toLowerCase() === 'concept'

        const styling = {
            isHorizontalSticky: horizontalSticky,
            isEditMode: editMode,
            isPrinting: printing,
        } as JobFamilyMatrixStylingProps

        const renderFamilyMatrixImages = useMemo(() => {
            if (
                !familyMatrixImages.imageVersions ||
                familyMatrixImages.imageVersions.length === 0
            ) {
                return (
                    <DownloadListNoItems>
                        {lang.noExistingFiles}
                    </DownloadListNoItems>
                )
            }

            return familyMatrixImages.imageVersions
                .map((image: JobMatrixImageProps) => (
                    <DownloadListItem key={image.version}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2px',
                            }}
                        >
                            <span>{image.description || 'No description'}</span>
                            <small>{formatImageVersion(image.version)}</small>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                        }}>
                            <IconButton onClick={() => handleDownloadImageVersion(image)} color={'primary'} IconSVG={DownloadIcon} />
                            <AreYouSurePopup
                                onClick={() => handleDeleteImageVersion(image)}
                                action={lang.deletePlural}
                                item={lang.jobFamilyVersion}
                                InnerContent={() =>
                                    <IconButton color={'primary'} IconSVG={TrashIcon} onClick={() => { }} />
                                }
                            />

                        </div>
                    </DownloadListItem>
                ))
                .reverse()
        }, [familyMatrixImages.imageVersions])

        const renderImageVersionView = () => (
            <Popup
                currentLanguage={currentLanguage}
                buttonText={lang.newJobFamily}
                title={lang.versionManagement}
                popupHandler={handleFamilyMatrixImagesPopup}
                popupState={familyMatrixImages.imagePopupState}
            >
                <DownloadListContainer>
                    {renderFamilyMatrixImages}
                </DownloadListContainer>
                <TextField
                    type={'text'}
                    value={familyMatrixImages.uploadDetails.description}
                    placeholder={lang.writeDescription}
                    valid
                    onChange={e => handleImageDescription(e.target.value)}
                    style={{
                        marginBottom: 5,
                    }}
                />
                <Button
                    processing={printing || loadingFamilyMatrixImages}
                    disabled={!familyMatrixImages.uploadDetails.description}
                    onClick={handleCreateNewVersion}
                    size='stretch'
                    text={lang.createNewVersion}
                />
            </Popup>
        )

        const renderPrintingOverlay = () => {
            if (!printing) return null
            if (familyMatrixImages.imagePopupState) return null
            return <AwaitIsPrinting />
        }

        return (
            <>
                {renderImageVersionView()}
                {renderPrintingOverlay()}
                {visibleJobFamilyOptions && (
                    <ManageJobFamilyPopup
                        results={showTheseResults}
                        family={jobfamily}
                        options={filterFields}
                        setOptions={setFilterFields}
                        handlePopup={handleVisibilityManager}
                        currentLanguage={currentLanguage}
                        editMode={editMode}
                    />
                )}
                <JobFamilyMatrixContainer>
                    <Matrix ref={ref} isPrinting={printing}>
                        <PrintingContainer isPrinting={printing}>
                            <PrintingInnerContainer isPrinting={printing}>
                                <JobFamilyMatrixHeader>
                                    <MatrixLogo>
                                        <img
                                            style={{
                                                maxHeight: '100px',
                                                maxWidth: '200px',
                                            }}
                                            src={
                                                currentOrganisation?.imageUrl
                                                    ? API_URL +
                                                    currentOrganisation?.imageUrl
                                                    : require('assets/images/logo.png')
                                            }
                                            alt='Organisation logo'
                                        />
                                    </MatrixLogo>
                                    <MatrixDetails>
                                        <H1>{jobfamily.name}</H1>
                                    </MatrixDetails>
                                    <DateHeader />
                                </JobFamilyMatrixHeader>
                                <ScrollContainer
                                    hideScrollbars={printing}
                                    vertical={true}
                                    horizontal={true}
                                    style={cssStylingScrollContainer}
                                    ignoreElements={
                                        '.quill, textarea, select, input'
                                    }
                                >
                                    {showDescriptionFields() && (
                                        <JobFamilyHeaderTable
                                            style={{
                                                zoom: printing
                                                    ? '100%'
                                                    : zoomTable + '%',
                                            }}
                                            columns={showTheseResults.length}
                                        >
                                            <JobFamilyDetails>
                                                <DetailsColumns
                                                    columns={
                                                        showTheseResults.length
                                                    }
                                                >
                                                    <ConnectedMatrixBoxEditable
                                                        id={jobfamily.id}
                                                        field={'primaryDescription'}
                                                        text={primaryDescription}
                                                        styling={styling}
                                                        put='family'
                                                        type='description'
                                                    />
                                                    <ConnectedMatrixBoxEditable
                                                        id={jobfamily.id}
                                                        field={
                                                            'secondaryDescription'
                                                        }
                                                        text={secondaryDescription}
                                                        styling={styling}
                                                        put='family'
                                                        type='description'
                                                    />
                                                </DetailsColumns>
                                            </JobFamilyDetails>
                                        </JobFamilyHeaderTable>
                                    )}
                                    <Table
                                        columns={showTheseResults.length}
                                        isPrinting={printing}
                                        zoom={zoomTable}
                                    >
                                        <ConnectedRowStyrGroup
                                            results={showTheseResults}
                                            currentLanguage={currentLanguage}
                                            styling={styling}
                                        />
                                        <RowTalentpath
                                            results={showTheseResults}
                                            currentLanguage={currentLanguage}
                                            styling={styling}
                                        />
                                        <RowLevel
                                            alternativeTitle={
                                                currentOrganisation?.enabledStyrLevelSubs
                                                    ? currentOrganisation?.alternativeTitle
                                                    : undefined
                                            }
                                            results={showTheseResults}
                                            family={jobfamily}
                                            currentLanguage={currentLanguage}
                                            styrLevelSub={
                                                currentOrganisation?.alternativeLevels
                                            }
                                            organisationLevelsAvailable={
                                                currentOrganisation?.enabledStyrLevelSubs
                                            }
                                            styling={styling}
                                            verticalStickySettings={verticalStickySettings}
                                            updateVerticalSetting={updateVerticalStickySettings}
                                        />
                                        <RowJobRole
                                            results={showTheseResults}
                                            currentLanguage={currentLanguage}
                                            family={jobfamily}
                                            styling={styling}
                                            verticalStickySettings={verticalStickySettings}
                                            updateVerticalSetting={updateVerticalStickySettings}
                                        />
                                        <RowEditableOptionals
                                            filter={filterFields}
                                            family={jobfamily}
                                            results={showTheseResults}
                                            currentLanguage={currentLanguage}
                                            styling={styling}
                                        />
                                        <RowsDifferentiatingFactors
                                            filter={filterFields}
                                            family={jobfamily}
                                            results={showTheseResults}
                                            currentOrganisation={
                                                currentOrganisation
                                            }
                                            currentLanguage={currentLanguage}
                                            styling={styling}
                                        />
                                        <ConnectedRowsStyrCompetences
                                            filter={filterFields}
                                            results={showTheseResults}
                                            styling={styling}
                                        />
                                        {!printing && !isShareLink && (
                                            <RowNavigateTo
                                                results={showTheseResults}
                                                currentLanguage={currentLanguage}
                                                styling={styling}
                                            />
                                        )}
                                    </Table>
                                </ScrollContainer>
                                {printing && (
                                    <Footer>
                                        Powered by
                                        <StyrLogo
                                            src={require('assets/images/logo.png')}
                                        />
                                    </Footer>
                                )}
                            </PrintingInnerContainer>
                        </PrintingContainer>
                    </Matrix>
                    {!isShareLink && (
                        <BottomNavigation>
                            <JobFamilySwitchView>
                                <TableZoomController
                                    zoomTable={zoomTable || 100}
                                    handleZoom={handleZoom}
                                />
                                <JobFamilyMatrixDropUp>
                                    <JobFamilyMatrixDropUpContent>
                                        <div
                                            onClick={() =>
                                                handleDownloadMatrix(
                                                    DownloadTypes.Image
                                                )
                                            }
                                        >
                                            Image
                                        </div>
                                        <div
                                            onClick={() =>
                                                handleDownloadMatrix(
                                                    DownloadTypes.PDF
                                                )
                                            }
                                        >
                                            PDF
                                        </div>
                                    </JobFamilyMatrixDropUpContent>
                                    <IconButton
                                        IconSVG={PrintIcon}
                                        color='warning'
                                        onClick={() => { }}
                                    />
                                </JobFamilyMatrixDropUp>
                                <IconButton
                                    IconSVG={CalendarTime}
                                    onClick={handleFamilyMatrixImagesPopup}
                                    color={'warning'}
                                />
                                {canEdit && (
                                    <IconButton
                                        IconSVG={EditIcon}
                                        onClick={() => changeDisplayMode()}
                                        color={
                                            !editMode ? 'warning' : 'warningSticky'
                                        }
                                    />
                                )}
                                <IconButton
                                    IconSVG={ThumbTackIcon}
                                    onClick={() => changeHorizontalStickyMode()}
                                    color={!horizontalSticky ? 'warning' : 'warningSticky'}
                                />
                            </JobFamilySwitchView>
                            <JobFamilySwitchView
                                style={{
                                    width: '400px',
                                }}
                            >
                                {editMode && (
                                    <Button
                                        text={lang.manageRows}
                                        onClick={handleVisibilityManager}
                                    />
                                )}
                                <FormDropdownCheckListEmpty
                                    label={lang.filterResults}
                                    openInverted
                                    button
                                >
                                    {sortedResultsInCurrentFamily.map(
                                        (res, index) => {
                                            const isShown = showResults.includes(
                                                res.id
                                            )
                                            const alternativeLevel =
                                                currentOrganisation?.enabledStyrLevelSubs &&
                                                currentOrganisation
                                                    .alternativeLevels[res.level]
                                            return (
                                                <Label
                                                    key={res.level}
                                                    lastOfType={
                                                        sortedResultsInCurrentFamily.length -
                                                        1 ===
                                                        index
                                                    }
                                                >
                                                    <NumberTag
                                                        level={res.level}
                                                        size={32}
                                                        alternativeLevel={
                                                            alternativeLevel ||
                                                            undefined
                                                        }
                                                    />
                                                    <span>{res.name}</span>
                                                    <input
                                                        type='checkbox'
                                                        value={res.level}
                                                        onChange={() =>
                                                            changeVisibilityLevel(
                                                                res.id
                                                            )
                                                        }
                                                        checked={isShown}
                                                    />
                                                </Label>
                                            )
                                        }
                                    )}
                                </FormDropdownCheckListEmpty>
                            </JobFamilySwitchView>
                        </BottomNavigation>
                    )}
                </JobFamilyMatrixContainer>
            </>
        )
    }
