import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { LoadValidatedProfiles } from 'store/allocator/allocator.actions';
import { UpdateOrganisationRequest } from 'store/user/user.actions';
import { LevelsOptionsArray } from 'store/user/user.types';
import { Language, MultiLingualObject } from 'types/MultiLingualString';
import { Organisation, OrganisationUnit, MatrixLevelNotesObject } from 'types/Organisation';
import { Result } from 'types/Result';

export default interface JobMatrixResultsProps {
    resultsGetResults: () => void; // Fetch the latest results from the server.
    language?: Language; // The possible language from the URL: this is for print pdf links.
    currentLanguage: Language; // The current language to display the screen in.
    fullScreen?: boolean; // If we should display/style the matrix in fullscreen.
    currentOrganisation?: Organisation; // The current organisation.
    remoteData: RemoteJobMatrixData; // The data from the server about the job matrix.
    updateOrganisationRequest: UpdateOrganisationRequest;
    getRemoteLanguages: () => void;
    loadValidatedProfiles: LoadValidatedProfiles;
    levels: string[];
    isShareLink?: boolean;
    matrixView: MatrixViews;
}

export interface RemoteJobMatrixData {
    organisationUnits: OrganisationUnit[];
    results: Result[];
    extraColumns: {
        column1?: MultiLingualObject<MatrixLevelNotesObject>;
        column2?: MultiLingualObject<MatrixLevelNotesObject>;
        column3?: MultiLingualObject<MatrixLevelNotesObject>;
    }
    levelOptions: LevelsOptionsArray;
}

// The settings buttons component
export interface ColumnSettingsProps {
    onClickToggleVisibility: () => void;
    onClickEnlarge?: () => void;
    onClickShrink?: () => void;
    columnData?: string;
    vertical?: boolean;
    stickyState?: boolean;
    setIsSticky?: () => void;
    onClickToggleSticky?: () => void;
    stickyData?: string;
}

export interface StickyColumnProps {
    show?: boolean;
    state?: boolean;
    onPress?: () => void;
}

// Local reducer actions
interface InitAction {
    type: "init";
    payload: any;
    organisationId: string | undefined;
}

interface ToggleVisibilityColumnAction {
    type: "toggleVisibilityColumn";
    payload: any
}

interface ToggleVisibilityRowAction {
    type: "toggleVisibilityRow";
    payload: any
}

interface ToggleStickyColumnAction {
    type: "toggleStickyColumn";
    payload: any;
}

interface ToggleVisibilityTalenpathAction {
    type: "toggleVisibilityTalentpath";
    payload: any;
}

interface EnlargeWidth {
    type: "enlargeWidthColumn";
    payload: any
}

interface ShrinkWidth {
    type: "shrinkWidthColumn";
    payload: any
}

interface ToggleTagsAction {
    type: "toggleTags";
    payload: any;
}

export type LocalJobMatrixDataActions = InitAction |
    ToggleStickyColumnAction |
    ToggleVisibilityRowAction |
    ToggleVisibilityColumnAction |
    ToggleVisibilityTalenpathAction |
    EnlargeWidth |
    ShrinkWidth |
    ToggleTagsAction;

export const initialLocalJobMatrixData = {
    rows: [],
    columns: [],
    talentpath: [],
    tags: [],
}

export interface LocalJobMatrixData {
    rows: LocalRowData[];
    columns: LocalColumnData[];
    talentpath: LocalTalentpathData[];
    tags: LocalFilterTagData[];
}

export interface LocalTalentpathMatrixData {
    rows: LocalRowData[];
    talentpaths: LocalTalentpathData[];
}

export interface LocalRowData {
    uuid: string;
    shown: boolean;
}

export interface LocalColumnData {
    index: number;
    uuid: string;
    shown: boolean;
    width?: number;
    sticky: boolean;
}

export interface LocalTalentpathData {
    uuid: string;
    shown: boolean;
    width?: number;
}

export interface LocalFilterTagData{
    uuid: string;
    shown: boolean;
}
