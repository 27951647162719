import React from 'react';
import QuestionNode from 'types/QuestionNode';
import Answer from 'types/Answer';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    setSelectedAnswers,
} from 'store/allocator/allocator.actions';
import ReduxStore from 'store/store.type';
import { Language } from 'types/MultiLingualString';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';

export const Information: React.FC<QuestionProps> = ({
    selectedAnswers,
    currentLanguage,
}) => {
    const getInformationFromAnswer = selectedAnswers.find((x: Answer) => x.info);
    const title = getInformationFromAnswer?.info?.title ?
        getStringFromCurrentLanguage(
            getInformationFromAnswer.info?.title,
            currentLanguage
        ) : ''

    const content = getInformationFromAnswer?.info?.text ?
        getStringFromCurrentLanguage(
            getInformationFromAnswer.info?.text,
            currentLanguage
        ) : ''

    if (content === '' || title === '') return null

    const strippedTitle = title.replace(/(<([^>]+)>)/gi, "");

    return <Container>
        <Content>
            <Title>{strippedTitle}</Title>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </Content>
    </Container>
};

export const Container = styled.div`
    padding: 20px 0;
    bottom: 70px;
    width: 100%;
    display: grid;
    margin-top: 20px;
    align-items: center;
    background-color: #cfdeed;
    box-sizing: border-box;
    height: 250px;
    overflow-y: scroll;
`;

export const Content = styled.div`
    margin: 0px auto;
    width: 100%;
    max-width: 960px;
`;

export const Title = styled.h3`
    font-size: 1.5em;
    font-weight: 700;
    margin: 0;
`;

interface QuestionProps {
    currentQuestion: QuestionNode;
    currentLanguage: Language;
    selectedAnswers: Answer[];
    setSelectedAnswers: Function;
}

const mapStateToProps = (state: ReduxStore) => ({
    currentQuestion: state.allocator.questions.currentQuestion,
    currentLanguage: state.language.currentLanguage,
    selectedAnswers: state.allocator.questions.selectedAnswers,
});
const mapDispatchToProps = {
    setSelectedAnswers,
};
const ConnectedInformation = connect(
    mapStateToProps,
    mapDispatchToProps
)(Information);

export default ConnectedInformation;
